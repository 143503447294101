import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AbstractSubmissionWrapper } from './components/screens/abstracts-submission/AbstractSubmissionWrapper';
import HomeWrapper from './components/screens/home/HomeWrapper';
import { AboutWrapper } from './components/screens/about/AboutWrapper';
import { FeesWrapper } from './components/screens/fees/FeesWrapper';
import { ProceedingsWrapper } from './components/screens/proceedings/ProceedingsWrapper';
import { TopicWrapper } from './components/screens/topics/TopicWrapper';
import { HotelWrapper } from './components/screens/hotels/HotelWrapper';
import { SpeakersWrapper } from './components/screens/speakers/SpeakersWrapper';
import { UserHome } from './components/screens/user-home/UserHome';
import { RegisteredAbstractWrapper } from './components/screens/registered-abstracts/RegisteredAbstractWrapper';
import { Profile } from './components/screens/profile/Profile';
import { ViewAbstract } from './components/screens/abstract/ViewAbstract';
import { Workshops } from './components/screens/workshops/Workshops';
import { AbstractEditWrapper } from './components/screens/edit-abstract/AbstractEditWrapper';
import { AdminAbstract } from './components/screens/admin/AdminAbstract';
import { RevisorHome } from './components/screens/revisor/RevisorHome';
import { RevisorViewAbstract } from './components/screens/revisor/RevisorViewAbstract';
import { CoordinatorHome } from './components/screens/coordinator/CoordinatorHome';
import { CoordinatorViewAbstract } from './components/screens/coordinator/CoordinatorViewAbstract';
import { CorrespondingRevisorHome } from './components/screens/coordinator/CorrespondingRevisorHome';
import { CoordinatorRevisorViewAbstract } from './components/screens/coordinator/CoordinatorRevisorViewAbstract';
import { SpecialIssue } from './components/screens/specialIssue/SpecialIssue';
import { AcceptanceLettersWrapper } from './components/screens/acceptance-letters/AcceptanceLettersWrapper';
import { AcceptanceLetterDiv } from './components/screens/acceptance-letters/AcceptanceLetterDiv';
import { Payments } from './components/screens/payments/Payments';
import { AdminReceiptView } from './components/screens/admin/AdminReceiptView';
import { Receipt } from './components/screens/payments/Receipt';
import { Scholarships } from './components/screens/payments/Scholarships';
import { AdminScholarshipView } from './components/screens/admin/AdminScholarshipView';
import { ScholarshipView } from './components/screens/admin/ScholarshipView';
import { Schedule } from './components/screens/schedule/Schedule';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeWrapper />,
  },
  {
    path: '/home',
    element: <UserHome />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/special-issue',
    element: <SpecialIssue />,
  },
  {
    path: '/workshops',
    element: <Workshops />,
  },
  {
    path: '/about',
    element: <AboutWrapper />,
  },
  {
    path: '/payments',
    element: <Payments />,
  },
  {
    path: '/schedule',
    element: <Schedule />,
  },
  {
    path: '/speakers',
    element: <SpeakersWrapper />,
  },
  {
    path: '/hotels',
    element: <HotelWrapper />,
  },
  {
    path: '/acceptance-letters',
    element: <AcceptanceLettersWrapper />,
  },
  {
    path: '/acceptance-letters/:id',
    element: <AcceptanceLetterDiv />,
  },
  {
    path: '/topics/:id',
    element: <TopicWrapper />,
  },
  {
    path: '/fees',
    element: <FeesWrapper />,
  },
  {
    path: '/proceedings',
    element: <ProceedingsWrapper />,
  },
  {
    path: '/abstracts',
    element: <RegisteredAbstractWrapper />,
  },
  {
    path: '/abstracts/submit',
    element: <AbstractSubmissionWrapper />,
  },
  {
    path: '/admin/abstracts',
    element: <AdminAbstract />,
  },
  {
    path: '/admin',
    element: <AdminReceiptView />,
  },
  {
    path: '/revisor',
    element: <RevisorHome />,
  },
  {
    path: '/scholarships',
    element: <Scholarships />,
  },
  {
    path: '/admin/scholarships',
    element: <AdminScholarshipView />,
  },
  {
    path: '/admin/scholarships/:id',
    element: <ScholarshipView />,
  },
  {
    path: '/coordinator',
    element: <CoordinatorHome />,
  },
  {
    path: '/coordinator/revisor',
    element: <CorrespondingRevisorHome />,
  },
  {
    path: '/coordinator/abstracts/:id',
    element: <CoordinatorViewAbstract />,
  },
  {
    path: '/coordinator/revisor/abstracts/:id',
    element: <CoordinatorRevisorViewAbstract />,
  },
  {
    path: '/revisor/abstracts/:id',
    element: <RevisorViewAbstract />,
  },
  {
    path: '/abstracts/edit/:id',
    element: <AbstractEditWrapper />,
  },
  {
    path: '/receipts/:id',
    element: <Receipt />,
  },
  {
    path: '/abstracts/:id',
    element: <ViewAbstract />,
  },
]);

export const Logout = () => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_in');
  localStorage.removeItem('token_type');
};
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
