import PropTypes from 'prop-types';
import './speakers.css';
import { TopicsList } from '../../../data/TopicsList';

export const Speaker = ({ speaker, handleClick }) => {
  console.log(speaker);
  return (
    <div className="snn-speaker-card" onClick={handleClick}>
      <div className="snn-speakers-card-img">
        <img src={speaker.img} alt={speaker.name} />
        <div className="snn-speaker-shield">
          <img src={TopicsList.filter((t) => t.id === speaker.topic_id)[0].src_shield} />
        </div>
      </div>
      <div
        className={
          'snn-speakers-card-description' +
          {
            1: ' snn-user-home-abstract-advanced',
            2: ' snn-user-home-abstract-bio',
            3: ' snn-user-home-nanophotonic',
            4: ' snn-user-home-catalysis',
            5: ' snn-user-home-innovation',
          }[speaker.topic_id]
        }
      >
        <h2>{speaker.name}</h2>
        <p>{speaker.affiliation}</p>
      </div>
    </div>
  );
};

Speaker.propTypes = {
  speaker: PropTypes.any,
  handleClick: PropTypes.func,
};
