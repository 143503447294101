import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import SpecialIssueBanner from './Revista_SNN2024.jpg';
import Editor from './Thematic+Issue+Promotion+SNN2024-1.png';

export const SpecialIssue = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <section className="snn-page-section">
          <h1 className="snn-section-title">Special Issue</h1>
          <div className="special-issue-banner-wrapper">
            <img src={SpecialIssueBanner} />
          </div>
          <div className="special-issue-banner-wrapper">
            <img src={Editor} />
          </div>
          <p>Editors:</p>
          <ul>
            <li>Ruben D. Cadena-Nava, Universidad Nacional Autónoma de México (UNAM), Mexico</li>
            <li>Uriel Caudillo-Flores, Universidad Nacional Autónoma de México (UNAM), Mexico</li>
            <li>Mario H. Farias-Sanchez, Universidad Nacional Autónoma de México (UNAM), Mexico</li>
            <li>
              Raul Rangel-Rojo, Center for Scientific Research and Higher Education at Ensenada
              (CICESE), Mexico
            </li>
          </ul>

          <p>
            The objective of this thematic issue is to feature novel and high-quality full papers
            derived from the works presented at SNN 2024. Reviews, letters and perspectives will
            also be considered; proceedings, on the other hand, will not be considered. Furthermore,
            submissions are encouraged from researchers who wish to contribute original papers on
            the topics but are unable to attend the symposium. The scope of this issue encompasses
            the field of nanoscience and nanomaterials, covering a wide range of relevant
            theoretical, computational, and experimental topics, including (but not limited to):
          </p>
          <ul>
            <li>
              <strong>Advanced materials and physics of nanostructures:</strong> design, synthesis,
              and characterization of novel nanomaterials, as well fundamental properties and
              behavior of nanostructures at the atomic and molecular levels.
            </li>
            <li>
              <strong>Bionanotechnology:</strong> research at the interface of biology and
              nanotechnology, which covers the study of biological phenomena at the nanoscale,
              development of new bioinspired materials, biomaterials and nanomedicine.
            </li>
            <li>
              <strong>Nanophotonics:</strong> interactions of light with nanoscale structures;
              design, fabrication, and characterization of nanophotonic devices for applications
              spanning optical sensing, imaging, communication, and energy conversion.
            </li>
            <li>
              <strong>Physicochemistry of nanomaterials and catalysis at the nanoscale:</strong>{' '}
              physical and chemical properties of nanomaterials, including their synthesis,
              structure, surface chemistry, interactions, and catalytic processes.
            </li>
            <li>
              <strong>Tech innovation in nanoscience:</strong> breakthroughs in the field of
              nanoscience including fabrication techniques, instrumentation, and computational
              modeling.
            </li>
          </ul>

          <p>
            For more information visit:{' '}
            <a
              style={{ textDecoration: 'underline', color: '#225aa9' }}
              href="https://www.beilstein-journals.org/bjnano/home"
            >
              Beilstein Journal of Nanotechnology
            </a>
          </p>
          <p>
            <strong>Submission deadline: March 31, 2025</strong>
          </p>
        </section>
      </div>
    </>
  );
};
