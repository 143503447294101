import './Proceedings.css';

export const Proceedings = () => {
  return (
    <>
      <div className="snn-page-section">
        <p>Proceedings from the past symposia:</p>

        <div className="snn-proceeding-wrapper">
          <p>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://nnsymposium.s3.us-west-1.amazonaws.com/SNN2024_Proceedings.pdf"
            >
              Symposium of Nanoscience and Nanomaterials 2024
            </a>
          </p>
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://nnsymposium.s3.us-west-1.amazonaws.com/SNN2022_Proceedings.pdf"
            >
              Symposium of Nanoscience and Nanomaterials 2022
            </a>
          </p>
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://nnsymposium.s3.us-west-1.amazonaws.com/proceedings/proceedings_2019-2.pdf"
            >
              Symposium of Nanoscience and Nanomaterials 2019
            </a>
          </p>
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://nnsymposium.s3.us-west-1.amazonaws.com/proceedings/PROCEEDINGS+IVSNN.pdf"
            >
              IV Symposium of Nanoscience and Nanomaterials (2018)
            </a>
          </p>
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://nnsymposium.s3.us-west-1.amazonaws.com/proceedings/Memorias_SimposioCNyN_2017.pdf"
            >
              III Symposium of Nanoscience and Nanomaterials (2017)
            </a>
          </p>
          <p>
            <a rel="noopener noreferrer" target="_blank" href="#">
              II Symposium of Nanoscience and Nanomaterials (2016)
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
