import PropTypes from 'prop-types';

export const UserMenu = ({ logoutButton }) => {
  return (
    <nav className="snn-user-menu-wrapper">
      <div>
        <span className="snn-user-menu-icon material-symbols-outlined">home</span>
        <p className="snn-user-menu-text">Home</p>
      </div>
      <div>
        <span className="material-symbols-outlined">library_books</span>
        <p className="snn-user-menu-text">Abstracts</p>
      </div>
      <div className="">
        <span className="snn-user-menu-icon material-symbols-outlined">add</span>
        <p className="snn-user-menu-text">Add abstract</p>
      </div>
      <div onClick={logoutButton}>
        <span className="material-symbols-outlined">logout</span>
        <p className="snn-user-menu-text">Logout</p>
      </div>
    </nav>
  );
};

UserMenu.propTypes = {
  logoutButton: PropTypes.func,
};
