import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { About } from './About';
import { Footer } from '../../footer/Footer';

export const AboutWrapper = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <About />
      </div>
      <Footer />
    </>
  );
};
