import { LoadingLayer } from '../../LoadingLayer';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TopicsList } from '../../../data/TopicsList';
import { saveAbstractRequest } from '../registered-abstracts/RegisteredAbstracts';
import { SideMenuAdmin } from './SideMenuAdmin';

export const fetchAbstracts = async (setAbstracts) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/admin/abstracts',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    const data = await response.json();
    console.log(data);
    setAbstracts(data.Items);
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const AdminAbstract = () => {
  const [abstracts, setAbstracts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage('Loading');
    fetchAbstracts(setAbstracts).then(() => {
      setIsLoading(false);
    });
  }, []);

  const deleteAbstract = (abstractId, updates_at) => {
    console.log('Deleting');
    setIsLoading(true);
    setLoadingMessage('Deleting abstracts');
    saveAbstractRequest({ abstract_id: abstractId, updated_at: updates_at }).then(() =>
      window.location.reload(),
    );
  };

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenuAdmin />
      <div className="snn-abstract-submission-main-grid-wrapper">
        <LoadingLayer isLoading={isLoading} action={loadingMessage} />

        <section className="snn-expand-grid-4">
          <h1 className="snn-user-home-main-content-header">Abstracts</h1>
          <div>
            {abstracts &&
              abstracts.map((abstract) => (
                <Link key={abstract.id + abstract.title} to={`/abstracts/${abstract.id}`}>
                  <div
                    className={
                      'snn-abstract-registered-card' +
                      {
                        'Advanced materials and physics of nanostructures':
                          ' snn-user-home-abstract-advanced',
                        Bionanotechnology: ' snn-user-home-abstract-bio',
                        Nanophotonics: ' snn-user-home-nanophotonic',
                        'Physical-chemistry of nanomaterials and nanocatalysis':
                          ' snn-user-home-catalysis',
                        'Tech innovation in nanoscience': ' snn-user-home-innovation',
                      }[
                        abstract.category
                          ? abstract.category
                          : 'Advanced materials and physics of nanostructures'
                      ] +
                      (abstract.status === 'Submitted'
                        ? ' snn-abstract-registered-card-submitted'
                        : ' snn-abstract-registered-card-draft')
                    }
                  >
                    <p className="snn-user-home-main-content-small-text">{abstract.status}</p>
                    {abstract.code === 'undefined' ? null : (
                      <p className="snn-user-home-main-content-small-text">{abstract.code}</p>
                    )}
                    <div className="snn-abstract-registered-card-text">
                      <div className="snn-abstract-admin-details">
                        <p className="snn-user-home-main-content-large-semi">{abstract.title}</p>
                        <p>
                          Cognito username: <strong>{abstract.cognito_username}</strong>
                        </p>
                        <p>
                          User email: <strong>{abstract.user_email}</strong>
                        </p>
                        <p>
                          Updated at: <strong>{abstract.updated_at}</strong>
                        </p>
                      </div>
                      {abstract.category ? (
                        <img
                          src={TopicsList.filter((t) => t.name === abstract.category)[0].src_bg}
                        />
                      ) : null}
                    </div>
                    <div className="snn-abstract-registered-card-buttons-wrapper">
                      <Link to={'/abstracts/edit/' + abstract.id}>
                        <div className="snn-abstract-registered-card-button">
                          Edit <span className="material-symbols-outlined">edit</span>
                        </div>
                      </Link>
                      <div
                        onClick={(event) => {
                          event.preventDefault();
                          deleteAbstract(abstract.id, abstract.updated_at);
                        }}
                        className="snn-abstract-registered-card-button snn-abstract-registered-card-button-delete"
                      >
                        Delete <span className="material-symbols-outlined">close</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};
