import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { Footer } from '../../footer/Footer';
import { Proceedings } from './Proceedings';

export const ProceedingsWrapper = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <Proceedings />
      </div>
      <Footer />
    </>
  );
};
