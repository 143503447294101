import Topic1 from '../assets/topics/topic_1.svg';
import Topic2 from '../assets/topics/topic_2.svg';
import Topic3 from '../assets/topics/topic_3.svg';
import Topic4 from '../assets/topics/topic_4.svg';
import Topic5 from '../assets/topics/topic_5.svg';

import BionanotechnologyBg from '../assets/topics/bio_bg.png';
import AdvancedMaterialsBg from '../assets/topics/advanced_materials.png';
import CatalysisBg from '../assets/topics/catalysis.png';
import NanoPhotonicsBg from '../assets/topics/nanophotonics.png';
import TechInnovationBg from '../assets/topics/tech.png';

import AdvancedMaterialsShield from '../assets/topics/topic_1.png';
import BionanotechnologyShield from '../assets/topics/topic_2.png';
import NanoPhotonicsShield from '../assets/topics/topic_3.png';
import CatalysisShield from '../assets/topics/topic_4.png';
import TechInnovationShield from '../assets/topics/topic_5.png';

export const TopicsList = [
  {
    id: 1,
    name: 'Advanced materials and physics of nanostructures',
    src: Topic1,
    description:
      'This topic is meant to display recent developments in functional materials, in particular those involving a nanoscience or nanotechnological approach. It covers recent innovative aspects of materials research on the design, synthesis, characterization and applications. In particular, we will focus on the most recent advances in the study of composites, hybrid and hetero structured materials, synthesis techniques and studies of their outstanding properties, as well as micro and nano-characterization techniques. Experimental and theoretical research are welcome for presentations. The symposium will involve invited, contributed, and poster presentations looking to propitiate and enhance interaction and collaboration between national and international researchers and also with potential and established manufacturers',
    src_bg: AdvancedMaterialsBg,
    src_shield: AdvancedMaterialsShield,
  },
  {
    id: 2,
    name: 'Bionanotechnology',
    src: Topic2,
    description:
      'Bionanotechnology refers to a knowledge area where two different disciplines converge: Biology and Nanotechnology. This research area is focused on the incorporation of molecules into nanovehicles. Bionanotechnology has widespread applications, such as therapeutics and diagnosis in medicine enhancing their pharmacokinetics and improving the specificity, bioremediation or improving the soil nutrients in agriculture. Nanocarriers, Virus-like Particles (VLP´s), toxicological evaluations, biosensors, disease therapy, diagnosis, and related research papers are welcome for oral and poster presentations. Our symposium is an excellent scenario to initiate, establish or enhance your national and international collaborations.',
    src_bg: BionanotechnologyBg,
    src_shield: BionanotechnologyShield,
  },
  {
    id: 3,
    name: 'Nanophotonics',
    src: Topic3,
    description:
      'The topic of Nanophotonics is aimed at recent advances in the design, fabrication, and characterization of novel nanostructured materials that have interesting optical properties, and are of importance for potential applications in different aspects of photonics and optoelectronics such as light emitters, detectors, nonlinear materials for information processing, plasmonic circuits, and correlated photon generation, to name a few. We will be accepting contributed oral and poster presentations centered on these topics, and will also consider contributions on other interesting aspects of Nanophotonics and their applications. The symposium is a good forum to bring together the community working on nanophotonics in the country, and to propitiate collaborations and strengthen existing ones in a relaxed atmosphere.',
    src_bg: NanoPhotonicsBg,
    src_shield: NanoPhotonicsShield,
  },
  {
    id: 4,
    name: 'Physical-chemistry of nanomaterials and nanocatalysis',
    src: Topic4,
    description:
      'The topic of Nanocatalysis is focused on cutting edge research to produce catalysts with 100 % selectivity, extremely high activity, low energy consumption, and long lifetime. Works including precise control and discussion about size, shape, spatial distribution, surface composition and electronic structure, and thermal and chemical stability of the individual nanocomponents and related characterization are welcome. Works aimed towards nanocatalysis active sites identification and understanding of interaction between active phases and reactants are especially encouraged. The effects of new active phases, nanostructures and nano shaped supports, pure and mixed materials, new reaction pathways and new applications are topics of interest. Works about catalysts scaling up for applications in environmental protection such as greenhouse gases valorization, pollutants removal in fuels and water bodies are also welcomed as well as on the industrial production of high value products.',
    src_bg: CatalysisBg,
    src_shield: CatalysisShield,
  },
  {
    id: 5,
    name: 'Tech innovation in nanoscience',
    src: Topic5,
    description:
      'The topic of Nanocatalysis is focused on cutting edge research to produce catalysts with 100 % selectivity, extremely high activity, low energy consumption, and long lifetime. Works including precise control and discussion about size, shape, spatial distribution, surface composition and electronic structure, and thermal and chemical stability of the individual nanocomponents and related characterization are welcome. Works aimed towards nanocatalysis active sites identification and understanding of interaction between active phases and reactants are especially encouraged. The effects of new active phases, nanostructures and nano shaped supports, pure and mixed materials, new reaction pathways and new applications are topics of interest. Works about catalysts scaling up for applications in environmental protection such as greenhouse gases valorization, pollutants removal in fuels and water bodies are also welcomed as well as on the industrial production of high value products.',
    src_bg: TechInnovationBg,
    src_shield: TechInnovationShield,
  },
];
