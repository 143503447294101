import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Logout } from '../../../App';
import SideMenuLogo from '../user-home/SideMenuLogo';

export const SideMenuCoordinator = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const logoutButton = () => {
    Logout();
    window.location.href = '/';
  };
  return (
    <nav
      className={
        isExpanded
          ? 'snn-user-home-side-menu-wrapper hide-on-mobile'
          : 'snn-user-home-side-menu-wrapper-not-expanded hide-on-mobile'
      }
    >
      <div className="snn-user-home-side-menu-logo-wrapper">
        <SideMenuLogo />
      </div>
      <div className="snn-user-home-side-menu-options-wrapper">
        <div className="snn-user-home-side-menu-hide" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? (
            <span className="material-symbols-outlined">chevron_left</span>
          ) : (
            <span className="material-symbols-outlined">chevron_right</span>
          )}
        </div>
        <Link to="/coordinator">
          <div className="snn-user-home-side-menu-option-wrapper">
            <span className="snn-user-menu-icon material-symbols-outlined">home</span>
            <p className="snn-user-home-main-content-small-text">Coordinator Home</p>
          </div>
        </Link>
        <Link to="/coordinator/revisor">
          <div className="snn-user-home-side-menu-option-wrapper">
            <span className="snn-user-menu-icon material-symbols-outlined">home</span>
            <p className="snn-user-home-main-content-small-text">Revisor Home</p>
          </div>
        </Link>
        <hr />
        <Link to="/abstracts">
          <div className="snn-user-home-side-menu-option-wrapper">
            <span className="snn-user-menu-icon material-symbols-outlined">library_books</span>
            <p className="snn-user-home-main-content-small-text">Registered abstracts</p>
          </div>
        </Link>
        <Link to="/acceptance-letters">
          <div className="snn-user-home-side-menu-option-wrapper">
            <span className="snn-user-menu-icon material-symbols-outlined">credit_score</span>
            <p className="snn-user-home-main-content-small-text">Acceptance letters</p>
          </div>
        </Link>
        <Link to="/payments">
          <div className="snn-user-home-side-menu-option-wrapper">
            <span className="snn-user-menu-icon material-symbols-outlined">receipt</span>
            <p className="snn-user-home-main-content-small-text">Receipt submission</p>
          </div>
        </Link>
        {/*<Link to="/scholarships">*/}
        {/*<div className="snn-user-home-side-menu-option-wrapper">*/}
        {/*<span className="snn-user-menu-icon material-symbols-outlined">receipt_long</span>*/}
        {/*<p className="snn-user-home-main-content-small-text">Scholarships</p>*/}
        {/*</div>*/}
        {/*</Link>*/}
        <hr />
        <div className="snn-user-home-side-menu-option-wrapper">
          <span className="snn-user-menu-icon material-symbols-outlined">logout</span>
          <p className="snn-user-home-main-content-small-text" onClick={logoutButton}>
            Logout
          </p>
        </div>
      </div>
    </nav>
  );
};
