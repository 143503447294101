import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingLayer } from '../../LoadingLayer';
import { SideMenuAdmin } from '../admin/SideMenuAdmin';

export const fetchReceiptById = async (abstractId, setAbstract, setStatus) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/admin/receipts/' +
        abstractId,
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    const data = await response.json();
    console.log(data.Items[0]);
    setAbstract(data.Items[0]);
    setStatus(data.Items[0].status);
    return data.Items[0];
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const Receipt = () => {
  let { id } = useParams();
  const [receipt, setReceipt] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Loading...');
  const [status, setStatus] = useState('approved');
  const navigate = useNavigate();

  const submitReceipt = async () => {
    setIsLoading(true);
    setLoadingMessage('Submitting...');
    const data = {
      status,
      updated_at: receipt.updated_at,
    };
    fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/admin/receipts/' + id,
      {
        method: 'POST',
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
        body: JSON.stringify(data),
      },
    )
      .then(() => {
        setIsLoading(false);
        navigate('/admin');
      })
      .then((data) => {
        console.log(data);
      });
  };

  useEffect(() => {
    fetchReceiptById(id, setReceipt, setStatus).then(() => {
      setIsLoading(false);
      setLoadingMessage('');
    });
  }, []);

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenuAdmin />
      <div className="snn-abstract-submission-main-grid-wrapper">
        <LoadingLayer isLoading={isLoading} action={loadingMessage} />
        <Link to={'/admin'}>
          <h1 className="snn-user-home-main-content-header snn-expand-grid-4 snn-link-download">
            <span className="material-symbols-outlined">arrow_back_ios</span> Return to receipt
          </h1>
        </Link>
        {receipt && (
          <div className="snn-expand-grid-4 snn-panel-form-wrapper">
            <div className="flex-start">
              <h2 className="snn-user-home-main-content-large-semi">{receipt.name} </h2>
              <p>Created at: {receipt.created_at}</p>
              <p>Food preferences: {receipt.food}</p>
              <p>Food allergies: {receipt.foodAllergies}</p>
              <p>Email for receipt: {receipt.email}</p>
              <p>Require invoice: {receipt.requireInvoice ? 'Yes' : 'No'}</p>
              <p>isStudent: {receipt.isStudent ? 'Yes' : 'No'}</p>
              <p>Statust: {receipt.status}</p>
              <a href={receipt.receipt} className="snn-link-download">
                <span className="material-symbols-outlined">sim_card_download</span> Download
                receipt
              </a>
              {receipt.isStudent && (
                <a href={receipt.studiesProof} className="snn-link-download">
                  <span className="material-symbols-outlined">sim_card_download</span> Download
                  student card
                </a>
              )}
              {receipt.requireInvoice && (
                <a href={receipt.fiscalSituation} className="snn-link-download">
                  <span className="material-symbols-outlined">sim_card_download</span> Download
                  invoice
                </a>
              )}
            </div>
            <div className="snn-expand-grid-4">
              <div className="snn-submit-abstract-buttons-wrapper flex-start">
                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option selected value="Chicken Cordon Bleu/Pollo Cordon Bleu">
                    Sent
                  </option>
                  <option name="degree" value="Creating reports">
                    Creating reports
                  </option>
                  <option name="degree" value="Receipt sent">
                    Receipt sent
                  </option>
                </select>
                <button onClick={submitReceipt} className="button">
                  Update status
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
