export const PublicOptions = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'Speakers',
    link: '/speakers',
  },
  {
    label: 'Program',
    link: '/schedule',
  },
  {
    label: 'Workshops',
    link: '/workshops',
  },
  {
    label: 'Fees & Fellowships',
    link: '/fees',
  },
  {
    label: 'Special Issue',
    link: '/special-issue',
  },
  {
    label: 'Hotel Accommodation',
    link: '/hotels',
  },
  {
    label: 'Proceedings',
    link: '/proceedings',
  },
  {
    label: 'About',
    link: '/about',
  },
];
