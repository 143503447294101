import LogoJeol from '../../../assets/images/sponsors/LOGO_JEOL_symbol_H_RGB.png';
import LogoEquipar from '../../../assets/images/sponsors/Logo_equipar.png';
import LogoIsasa from '../../../assets/images/sponsors/isasa.png';
import LogoCic from '../../../assets/images/sponsors/cic.png';
import LogoAcat from '../../../assets/images/sponsors/acat.jpeg';
import LogoEl from '../../../assets/images/sponsors/LOGO EI.png';
import LogoSpace from '../../../assets/images/sponsors/space.jpeg';
import LogoNavy from '../../../assets/images/sponsors/navy.jpeg';
import Dance from '../../../assets/images/sponsors/dance.jpeg';
import Artes from '../../../assets/images/sponsors/artes.png';
import UNAM from '../../../assets/images/sponsors/logo_unam.png';
import CICESE from '../../../assets/images/sponsors/logo_cicese.png';
import UABC from '../../../assets/images/sponsors/uabc.svg';
import CNYN from '../../../assets/images/sponsors/cnyn.jpeg';
import FIAD from '../../../assets/images/sponsors/fiad.svg';

export const Sponsors = () => {
  return (
    <>
      <section className="snn-page-section snn-topics">
        <h2 className="snn-title">Sponsors</h2>
        <div className="snn-topic-section snn-sponsors-section">
          <div>
            <img src={LogoIsasa} alt="Isasa logo" />
          </div>
          <div>
            <img src={LogoJeol} alt="JEOL logo" />
          </div>
          <div>
            <img src={LogoEquipar} alt="Equipar logo" />
          </div>
          <div>
            <img src={LogoEl} alt="Isasa logo" />
          </div>
        </div>
        <h2 className="snn-title">Participating institutions</h2>
        <div className="snn-topic-section snn-sponsors-section">
          <div>
            <img src={UNAM} alt="cic logo" />
          </div>
          <div>
            <img src={CNYN} alt="cic logo" />
          </div>
          <div>
            <img src={CICESE} alt="cic logo" />
          </div>
          <div>
            <img src={UABC} alt="cic logo" />
          </div>
          <div>
            <img src={FIAD} alt="FIAD logo" />
          </div>
        </div>
        <div className="snn-topic-section snn-sponsors-section">
          <div>
            <img src={LogoCic} alt="cic logo" />
          </div>
          <div>
            <img src={LogoAcat} alt="cic logo" />
          </div>
          <div>
            <img src={LogoNavy} alt="Navy logo" />
          </div>
          <div>
            <img src={LogoSpace} alt="Space logo" />
          </div>
        </div>
        <h2 className="snn-title">Cultural activities</h2>
        <div className="snn-topic-section snn-sponsors-section">
          <div></div>
          <div>
            <img src={Artes} alt="dance logo" />
          </div>
          <div>
            <img src={Dance} alt="dance logo" />
          </div>
          <div></div>
        </div>
      </section>
    </>
  );
};
