import { SideMenuRevisor } from './SideMenuRevisor';
import { useJwt } from 'react-jwt';
import { useEffect, useState } from 'react';
import { TopicsList } from '../../../data/TopicsList';
import { Link } from 'react-router-dom';
import { LoadingLayer } from '../../LoadingLayer';

export const fetchRevisorAbstracts = async (user_groups, setAbstracts, cognitoUser) => {
  let category = '';
  if (user_groups.includes('revisor-advanced-materials-and-physics-of-nanostructures')) {
    category = 'Advanced materials and physics of nanostructures';
  } else if (user_groups.includes('revisor-bionanotechnology')) {
    category = 'Bionanotechnology';
  } else if (user_groups.includes('revisor-nanophotonics')) {
    category = 'Nanophotonics';
  } else if (
    user_groups.includes('revisor-physical-chemistry-of-nanomaterials-and-nanocatalysis')
  ) {
    category = 'Physical-chemistry of nanomaterials and nanocatalysis';
  } else if (user_groups.includes('revisor-tech-inovation-in-science')) {
    category = 'Tech innovation in nanoscience';
  }
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/revisors/abstracts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-token': localStorage.getItem('id_token'),
        },
        body: JSON.stringify({ category: category }),
      },
    );
    if (response.status === 401) {
      return response.status;
    }
    const data = await response.json();
    let tempAbstracts = data.Items;
    for (let i = 0; i < tempAbstracts.length; i++) {
      tempAbstracts[i].abstractKey = (
        tempAbstracts[i].category
          .split(' ')
          .map((c) => c[0])
          .join('') +
        '-' +
        (i + 1)
      ).toUpperCase();
    }
    console.log(cognitoUser);
    setAbstracts(
      tempAbstracts.filter((a) => {
        return (
          a.status === 'Sent' && (a.score ? a.score.length < 2 : true) //&&
          // !(a.score ? a.score.filter((s) => s.cognito_username === cognitoUser).length === 1 : true)
        );
      }),
    );
    return response.status;
  } catch (error) {
    return error;
  }
};

export const RevisorHome = () => {
  const [abstracts, setAbstracts] = useState([]);
  const access_token = localStorage.getItem('id_token');
  const { decodedToken } = useJwt(access_token);
  const [cognitoUser, setCognitoUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Loading');

  useEffect(() => {
    console.log('decodedToken');
    if (decodedToken) {
      if (decodedToken['cognito:groups'].includes('revisor')) {
        setCognitoUser(decodedToken['cognito:username']);
        console.log(cognitoUser);
        fetchRevisorAbstracts(
          decodedToken['cognito:groups'],
          setAbstracts,
          decodedToken['cognito:username'],
        ).then(() => {
          setLoadingMessage('Done');
          setIsLoading(false);
          console.log('abstracts');
        });
      }
    }
  }, [decodedToken]);

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenuRevisor />
      <LoadingLayer isLoading={isLoading} action={loadingMessage} />
      <div className="snn-user-home-main-content">
        <h1 className="snn-user-home-main-content-header">Welcome</h1>
        <div className="snn-user-home-main-content-grid">
          <div className="snn-expand-grid-4">
            <div>Pending abstracts to review ({abstracts.length})</div>
            <div></div>
          </div>

          <div className="snn-expand-grid-4">
            {abstracts.map((abstract) => (
              <Link key={abstract.title} to={`/revisor/abstracts/${abstract.id}`}>
                <div
                  className={
                    'snn-abstract-registered-card' +
                    {
                      'Advanced materials and physics of nanostructures':
                        ' snn-user-home-abstract-advanced',
                      Bionanotechnology: ' snn-user-home-abstract-bio',
                      Nanophotonics: ' snn-user-home-nanophotonic',
                      'Physical-chemistry of nanomaterials and nanocatalysis':
                        ' snn-user-home-catalysis',
                      'Tech innovation in nanoscience': ' snn-user-home-innovation',
                    }[abstract.category] +
                    (abstract.status === 'Submitted'
                      ? ' snn-abstract-registered-card-submitted'
                      : ' snn-abstract-registered-card-draft')
                  }
                >
                  <p className="snn-user-home-main-content-small-text">{abstract.status}</p>
                  <div className="snn-abstract-registered-card-text">
                    <div className="snn-revisor-card-wrapper-content">
                      <p>{abstract.abstractKey}</p>
                      <p className="snn-user-home-main-content-large-semi">{abstract.title}</p>
                      <div>
                        <p className="snn-user-home-main-content-small-text snn-user-revisor-authors">
                          {abstract.authors.map((a, index) => {
                            if (a.correspondingAuthor) {
                              if (index > 0) {
                                return (
                                  <>
                                    <span key={index}>, </span>
                                    <strong key={a.lastName}>
                                      {a.lastName} {a.name.length > 0 ? a.name[0] + '.' : ''}
                                    </strong>
                                    <sup>, *</sup>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <strong key={a.lastName}>
                                      {a.lastName} {a.name.length > 0 ? a.name[0] + '.' : ''}
                                    </strong>
                                    <sup>*</sup>
                                  </>
                                );
                              }
                            } else {
                              if (index > 0) {
                                return (
                                  <>
                                    <span key={index}>, </span>
                                    <span key={a.lastName}>
                                      {a.lastName} {a.name.length > 0 ? a.name[0] + '.' : ''}
                                    </span>
                                  </>
                                );
                              } else {
                                return (
                                  <span key={a.lastName}>
                                    {a.lastName} {a.name.length > 0 ? a.name[0] + '.' : ''}
                                  </span>
                                );
                              }
                            }
                          })}
                        </p>
                        <p>
                          {abstract.authors
                            .filter((a) => a.correspondingAuthor)
                            .map((a) => a.email)
                            .join(', ')}
                        </p>
                        {abstract.score ? (
                          <p>
                            <strong>Number of revisions: </strong> {abstract.score.length}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <img src={TopicsList.filter((t) => t.name === abstract.category)[0].src_bg} />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
