import { useEffect, useState } from 'react';
import { Logout } from '../../../App';
import { useNavigate, useParams } from 'react-router-dom';
import { TopicsList } from '../../../data/TopicsList';
import parse from 'html-react-parser';
import { LoadingLayer } from '../../LoadingLayer';
import { fetchAbstractById } from '../abstract/ViewAbstract';
import { SideMenuCoordinator } from './SideMenuCoordinator';

export const postAbstractsReview = async (score) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/revisors/abstracts',
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'user-token': localStorage.getItem('id_token'),
        },
        body: JSON.stringify(score),
      },
    );
    if (response.status === 401) {
      return response.status;
    }
    return response.status;
  } catch (error) {
    return error;
  }
};

export const CoordinatorRevisorViewAbstract = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [abstract, setAbstract] = useState();

  const [affiliationsString, setAffiliationString] = useState('');
  const [authorsString, setAuthorString] = useState('');
  const [referencesString, setReferencesString] = useState('');
  const [score, setScore] = useState(3);
  const [comments, setComments] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Loading');

  const submitScore = () => {
    setIsLoading(true);
    setLoadingMessage('Submitting...');
    console.log('Submitting score');
    let scorePayload = {
      abstract_id: id,
      score: {
        score: score,
        message: rates.filter((rate) => rate.count === parseInt(score))[0].label,
      },
      updated_at: abstract.updated_at,
      comments: comments,
    };
    console.log(scorePayload);
    postAbstractsReview(scorePayload).then((result) => {
      if (result === 200) {
        setIsLoading(false);
        navigate('/revisor');
      }
    });
  };

  const rates = [
    {
      count: 1,
      label: "Don't accept",
    },
    {
      count: 2,
      label: 'Accepted poster',
    },
    {
      count: 3,
      label: 'Change oral to poster',
    },
    {
      count: 4,
      label: 'Accepted oral',
    },
  ];

  function createMarkup(html) {
    return {
      __html: html,
    };
  }

  const getReferencesString = (references) => {
    const referencesArray = references; //references.split(';');
    let temp = '';
    for (const aff in referencesArray) {
      if (aff > 0) {
        temp += '<br/>';
      }
      temp += `[${parseInt(aff) + 1}] ${referencesArray[aff]}`;
    }
    return `${temp}`;
  };

  useEffect(() => {
    let tempAuthorString = '';
    let tempAffiliationString = '';

    if (abstract === undefined) {
      return;
    }

    let affiliations = Array.from(
      new Set(abstract.authors.map((author) => author.affiliations).flat()),
    );

    for (const iAffiliation in affiliations) {
      if (tempAffiliationString.length > 0) {
        tempAffiliationString += '<br/> ';
      }
      tempAffiliationString += `<sup>${parseInt(iAffiliation) + 1}</sup> ${
        affiliations[iAffiliation]
      }`;
    }

    for (const author of abstract.authors) {
      // Add ", " to tempAuthorString if is not the first author
      if (tempAuthorString.length > 0) {
        tempAuthorString += ', ';
      }
      if (author.name === undefined) {
        continue;
      }
      if (author.correspondingAuthor === true) {
        tempAuthorString += `<strong>${author.lastName} ${
          author.name.length > 0 ? author.name[0] + '.' : ''
        }</strong>`;
      } else {
        tempAuthorString += `${author.lastName} ${
          author.name.length > 0 ? author.name[0] + '.' : ''
        }`;
      }
      // Add superscript to author
      if (author.affiliations) {
        tempAuthorString += `<sup>${author.affiliations
          .map((affiliation) => {
            return affiliations.indexOf(affiliation) + 1;
          })
          .join(', ')}</sup>`;
        if (author.correspondingAuthor) {
          tempAuthorString += `<sup>, *</sup>`;
        }
      }
    }
    setAuthorString('<p>' + tempAuthorString + '</p>');
    setAffiliationString(tempAffiliationString);
  }, [abstract]);

  useEffect(() => {
    if (abstract === undefined) {
      return;
    }
    setReferencesString(getReferencesString(abstract.references));
  }, [abstract]);

  useEffect(() => {
    fetchAbstractById(id, setAbstract).then((result) => {
      setIsLoading(false);
      if (result.id === undefined) {
        Logout();
        navigate('/');
      }
    });
  }, []);
  return (
    <div className="snn-user-home-main-wrapper">
      <LoadingLayer isLoading={isLoading} action={loadingMessage} />
      <SideMenuCoordinator />
      {abstract ? (
        <div className="snn-abstract-submission-main-grid-wrapper">
          <div
            className={
              'snn-expand-grid-4 snn-view-abstract-header ' +
              {
                'Advanced materials and physics of nanostructures':
                  ' snn-user-home-abstract-advanced',
                Bionanotechnology: ' snn-user-home-abstract-bio',
                Nanophotonics: ' snn-user-home-nanophotonic',
                'Physical-chemistry of nanomaterials and nanocatalysis': ' snn-user-home-catalysis',
                'Tech innovation in nanoscience': ' snn-user-home-innovation',
              }[abstract.category]
            }
          >
            <img src={TopicsList.filter((t) => t.name === abstract.category)[0].src_shield} />
            <div className="snn-view-abstract-header-title-wrapper">
              <h1 className="snn-view-abstract-header-title">{abstract ? abstract.title : null}</h1>
              <div className="snn-view-abstract-header-info-wrapper">
                <p>
                  <strong>Status:</strong> {abstract.status}
                </p>
                <p>
                  <strong>Category:</strong> {abstract.category}
                </p>
                <p>
                  <strong>Presentation type:</strong> {abstract.presentation_type}
                </p>
              </div>
            </div>
          </div>

          <div className="snn-expand-grid-4">
            <div className="snn-abstract-page snn-abstract-view-page-wrapper">
              <h1 className="snn-abstract-page-title">{abstract.title}</h1>
              {authorsString.length > 20 ? (
                <>
                  <p className="snn-abstract-page-authors">{parse(authorsString)}</p>
                </>
              ) : (
                <></>
              )}
              {affiliationsString.length > 13 ? (
                <>
                  <p className="snn-abstract-page-affiliation">{parse(affiliationsString)}</p>
                </>
              ) : (
                <></>
              )}
              {abstract.background.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Introduction</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.background)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {abstract.methodology.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Methodology</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.methodology)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {abstract.results.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Results</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.results)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {abstract.img_path && (
                <img
                  width={abstract.image_width}
                  height={abstract.image_height}
                  className="snn-abstract-img"
                  src={
                    abstract.img_path.substring(0, 60) +
                    encodeURIComponent(abstract.img_path.substring(60))
                  }
                  alt="Abstract"
                />
              )}
              {abstract.img_path ? (
                <>
                  <p className="snn-abstract-page-image-footer">{abstract.image_footer}</p>
                </>
              ) : (
                <></>
              )}
              {abstract.conclusion.length > 8 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Conclusion</h2>
                  <div
                    className="snn-abstract-page-text"
                    dangerouslySetInnerHTML={createMarkup(abstract.conclusion)}
                  ></div>
                </>
              ) : (
                <></>
              )}
              {referencesString.length > 4 ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">References</h2>
                  <p className="snn-abstract-page-text">{parse(referencesString)}</p>
                </>
              ) : (
                <></>
              )}
              {abstract.acknowledgments ? (
                <>
                  <h2 className="snn-abstract-page-sub-title">Acknowledgments</h2>
                  <p className="snn-abstract-page-text">{abstract.acknowledgments}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="snn-expand-grid-4 snn-panel-form-wrapper">
            <h2 className="title-with-icon snn-user-home-main-content-large-semi">
              Add comments <span className="material-symbols-outlined">rate_review</span>
            </h2>
            <textarea
              onChange={(e) => setComments(e.target.value)}
              value={comments}
              className="textarea-comment"
              rows="15"
              cols="60"
            ></textarea>
            <h2 className="title-with-icon snn-user-home-main-content-large-semi score-title">
              Add score <span className="material-symbols-outlined">star_rate</span>
            </h2>
            <select
              id="category_id"
              name="category_id"
              className="select-score"
              value={score}
              onChange={(e) => setScore(e.target.value)}
            >
              {rates.map((rate) => (
                <option key={rate.count} value={rate.count}>
                  {rate.label}
                </option>
              ))}
            </select>
          </div>
          <div className="snn-expand-grid-4 snn-panel-form-wrapper">
            <div className="snn-submit-abstract-buttons-wrapper flex-start">
              <button className="button" onClick={submitScore}>
                Submit review{' '}
              </button>
            </div>
          </div>
          {/*{abstract.score ? (*/}
          {/*  <div className="snn-expand-grid-4 snn-panel-form-wrapper">*/}
          {/*    <h2 className="title-with-icon snn-user-home-main-content-large-semi">*/}
          {/*      Previous reviews <span className="material-symbols-outlined">reviews</span>*/}
          {/*    </h2>*/}
          {/*    {abstract.score.map((score) => (*/}
          {/*      <div key={score.score + score.message} className="snn-review-revisor">*/}
          {/*        {score.email ? <p>Revisor: {score.email}</p> : null}*/}
          {/*        <p>Message: {score.message}</p>*/}
          {/*        {score.comments ? <p>Comments: {score.comments}</p> : null}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*) : null}*/}
        </div>
      ) : null}
    </div>
  );
};
