import AFMWorkshop from '../assets/workshops/afm_workshop.png';
import FerroElectricWorkshop from '../assets/workshops/ferroelectric_materials.png';
import RamanWorkshop from '../assets/workshops/raman.png';
import NonCovalentWorkshop from '../assets/workshops/non-covalent.png';
import ALDWorkshop from '../assets/workshops/ald.jpg';
import IlluminatingWorkshop from '../assets/workshops/illuminating.jpg';
import ProteinEgineeringWorkshop from '../assets/workshops/proteion_engineering.png';

export const WorkshopsList = [
  {
    name: 'ATOMIC LAYER DEPOSITION WORKSHOP',
    instructors: ['Dr.  Hugo Tiznado V'],
    institution: 'CNyN-UNAM',
    summary:
      'Thede Nanoscience’s and Nanotechnology (CNyN) Center  has an industrial team with the latest generation equipment and a system designed by CNyN academics that allow the growth of materials through the atomic layer deposition technique. This material growth technique is a sequential method that enables obtaining materials with precise control over thickness growth, as well as ensuring surface homogeneity. It is a self-limiting technique with a high aspect ratio. This technique also facilitates assembling different layers in situ to form ultrathin multilayers, characterized by individual layers of different materials (dielectrics, metals, nitrides, etc.), alternated while maintaining a determined thickness ratio, stacking up to achieve a thin film with a fixed total thickness.',
    img: ALDWorkshop,
  },
  {
    name: 'AFM workshop',
    instructors: ['Dr. Eduardo Antonio Murillo Bracamontes'],
    institution: 'CNyN-UNAM',
    summary:
      'The objective of the AFM workshop is to comprehend the basic principles of Atomic Force Microscopy (AFM) in its various interaction modes, capture images of material surfaces using different analysis modes, and visualize and process the obtained images.',
    img: AFMWorkshop,
  },
  {
    name: 'Ferroelectric Materials',
    instructors: ['Dr. H’Linh Hmŏk', ' Dr. Subhash'],
    institution: 'CNyN-UNAM',
    summary:
      'The Ferroelectric Materials Workshop, with a comprehensive approach from theory to practice, will provide participants with fundamental and advanced skills in the preparation and understanding of ferroelectric materials. With a duration of 4 hours, the first half focuses on theory, offering a general introduction to ferroelectric materials and exploring their industrial and technological applications, as well as methods for preparing ferroelectric ceramics and thin films. The second half, practical and laboratory-oriented, the preparation of ceramic samples and ferroelectric thin films will be carried out using different techniques, and electrical properties will be measured using advanced measurement techniques.',
    img: FerroElectricWorkshop,
  },
  {
    name: 'Raman spectroscopy: Concepts and a novel perspective',
    instructors: ['DR. YASMÍN ESQUEDA BARRÓN'],
    institution: 'CICESE AND UNAM-CNYN',
    summary:
      "This workshop offers an approach to a significant surface characterization technique applied to materials as semiconductors, organic and biological matter, metal alloys, catalytic materials, among other, utilizing a non-destructive approach. To achieve the workshop's goals, participants will engage with fundamental concepts, gain familiarity with specialized instrumentation, and undertake result interpretation. Furthermore, the program will cover advancements in the field, in-situ measurements during diverse processes, and the enhancement of Raman signal through techniques such as SERS, TERS, and GERS.",
    img: RamanWorkshop,
  },
  {
    name: 'Vizualising non-covalent interactions in molecules and nanostructures',
    instructors: ['Dr. Carlos A. Corona García', 'Dr. Jonathan Guerrero Sanchez'],
    institution: 'Centro de Nanociencias y Nanotecnología, UNAM',
    summary:
      'This workshop will discuss non-covalent interactions and their role in several reactions of scientific and industrial relevance. First, we begin with the theoretical background behind the non-covalent interactions. After that, we will use computational codes to visualize them and also teach how to determine their strength (van der Waals, strong attraction, or repulsion). Finally, we will discuss their role in water splitting, the oxygen reduction reaction, and also atomic layer deposition.',
    img: NonCovalentWorkshop,
  },
  {
    name: 'ILLUMINATING HEALTH: EXPLORING LUMINESCENT MATERIALS IN BIOMEDICAL APPLICATIONS',
    instructors: ['Dr. Prakhar Sengar ', 'Dr. Gustavo A. Hirata'],
    institution: 'Centro de Nanociencias y Nanotecnología, UNAM',
    summary:
      'Luminescent materials, with their unique ability to emit light upon excitation, have revolutionized various fields, including biomedicine[1–4]. Recently. Moungi G. Bawendi, Louis E. Brus, and Aleksey Yekimov won the 2023 Nobel Prize in Chemistry for their pioneering work on quantum dots[5]. These materials serve as versatile tools, enabling advancements in imaging, sensing, and therapy within the biomedical realm.',
    img: IlluminatingWorkshop,
  },
  {
    name: 'Protein engineering for electrochemical biosensors',
    instructors: ['Dr. Raúl García Morales ', 'Dr. Andrés Zárate Romero '],
    institution:
      'Universidad Juárez Autónoma de Tabasco – Centro de Nanociencias y Nanotecnología, UNAM',
    summary:
      'The analytes of interest in clinical, environmental, or industrial samples are regularly analyzed by conventional analytical methods, such as colorimetric, spectrophotometric, and chromatographic, among others, which despite their high precision, have some disadvantages such as high costs, long analysis times and difficult sample preparation 1. To overcome these drawbacks, new technologies have been developed to allow their determination in a short time and with high precision. Among them, electrochemical biosensors, are autonomous integrated devices capable of providing specific quantitative or semi-quantitative analytical information using a biological recognition element. Moreover, such devices are easy to construct and operate, inexpensive, with high sensitivity and selectivity. These devices measure chemical substances through a sensing element of biological nature (enzymes, nucleic acids, antibodies, whole cells, etc.).',
    img: ProteinEgineeringWorkshop,
  },
];
