import Corona from './corona.jpeg';
import './Hotel.css';

export const HotelCoral = () => {
  return (
    <>
      <section className="snn-page-section snn-hotel-wrapper">
        <div className="snn-hotel-description">
          <h2 className="snn-title">CORONA, Hotel & Spa</h2>
          <p>End of reservation: April 15, 2024.</p>
          <p>Room Rates</p>
          <ul>
            <li>
              Phone:
              <ul>
                <li>(646) 176 09 01 Ext. 4565, 4566</li>
              </ul>
            </li>
            <li>
              WhatsApp:
              <ul>
                <li>(646) 255 9145</li>
              </ul>
            </li>
            <li>
              Email:
              <ul>
                <a className="snn-email-link" href="mailto:reserv@hotelcorona.com.mx">
                  reserv@hotelcorona.com.mx
                </a>
              </ul>
              <ul>
                <a className="snn-email-link" href="mailto:reserv2@hotelcorona.com.mx">
                  reserv2@hotelcorona.com.mx
                </a>
              </ul>
            </li>
            <li>
              Single room with one King bed or two queen beds (two people) $1,728.90 MXN. Rates
              include 13% room tax.
            </li>
            <li>
              Check-in: May 13, 2024 - May 17, 2024 (4 nights, regular rates apply for additional
              nights){' '}
            </li>
            <li>
              Reservation requirements: When your guests contact us to make a reservation, they will
              be required to provide a credit card for guarantee (16 digits, CVV code, and
              expiration date), guest name, and contact phone number.
            </li>
          </ul>
        </div>
        <div className="snn-hotel-image">
          <img src={Corona} alt="Hotel img" />
        </div>
      </section>
    </>
  );
};
