import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { Footer } from '../../footer/Footer';
import { Link, useParams } from 'react-router-dom';
import { TopicsList } from '../../../data/TopicsList';
import './Topics.css';

export const TopicWrapper = () => {
  const { id } = useParams();
  const topic = TopicsList.find((t) => t.id === parseInt(id));
  console.log(id);
  console.log(topic);
  return (
    <>
      {' '}
      {topic ? (
        <>
          <TopBar options={PublicOptions} />
          <div className="content">
            <>
              <div className="snn-page-section">
                <div className="snn-topic-description">
                  <div className="snn-logo-wrapper">
                    <img src={topic.src} alt="logo" />
                  </div>
                  <p className="snn-topic-description">{topic.description}</p>
                </div>
                <div>
                  <Link className="snn-back-button" to="/">
                    {' '}
                    <p>Back to home</p>
                  </Link>
                </div>
              </div>
            </>
          </div>
          <Footer />
        </>
      ) : null}
    </>
  );
};
