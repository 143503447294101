import AldoRomer from '../assets/images/speakers/aldo_romero.jpeg';
import ArturErbe from '../assets/images/speakers/artur_erbe.png';
import PierreBrevet from '../assets/images/speakers/pierre-francois brevet.png';
import WilliamGelbart from '../assets/images/speakers/william-gelbart.png';

export const SpeakersList = [
  {
    id: 0,
    name: 'Dr. Aldo Romero',
    affiliation: 'West Virginia University, USA',
    img: AldoRomer,
    topic_id: 1,
    resume:
      'Field of Expertise: Condensed Matter Theory and Computation\n' +
      'Research Focus: Computational Material Science\n' +
      'Pioneering Methods: Density Functional Theory, TDDFT, and Many Particle Approaches\n' +
      'Expanding Horizons: Computational High-Throughput\n',
    title:
      'Shattering Traditional Boundaries: The Integration of AI in Materials Science Discovery',
    abstract:
      'Artificial Intelligence (AI) is swiftly revolutionizing various aspects of human existence, with its most profound impact potentially unfolding in scientific exploration. The escalating technical complexities of today necessitate a rapid advancement in materials discovery as conventional methods need to meet the urgent requirements for innovative and transformative materials. Recent discourse has speculated that by 2050, AI could autonomously "generate" research of substantial value, prompting a reassessment of our methodologies, educational standards, and overall approaches. In this presentation, I will delve into the current landscape of AI applications in materials science, showcasing specific examples from recent research endeavors within my group. I will advocate for a paradigm shift from micro-level material investigations to more expansive, collaborative methodologies. This shift entails intensifying data generation efforts and cultivating synergistic environments with AI experts. I will offer insights into how materials science and condensed matter physics research are evolving under the influence of AI, proposing strategies for embracing these transformations rather than resisting them. The discourse will encompass novel educational frameworks, research initiatives, agency support, and the imperative for interdisciplinary and multidisciplinary partnerships. I will culminate by spotlighting successful implementations of these innovative methodologies and exploring the notion that AI\'s dependence on human input may be transient. The primary emphasis will be on the synergies fostered through effective human-AI collaborations, harmonizing human ingenuity and expertise with AI\'s prowess in managing vast datasets, discerning intricate patterns, navigating expansive hypothesis spaces, and executing repetitive tasks efficiently.',
  },
  {
    id: 1,
    name: 'Prof. Pierre-François Brevet',
    affiliation: 'Institut Lumière Matière, Université Claude Bernard Lyon 1, France',
    img: PierreBrevet,
    topic_id: 3,
    resume:
      'P.F. Brevet is interested in nanoscale materials, structure, organization and dynamics as observed with nonlinear optical methods. In a first line of research, plasmonic nanostructures (gold or silver nanoparticles synthesized through wet chemistry methods or realized with e-beam lithography) have been thoroughly investigated to understand their response for Second Harmonic Generation, effectively the conversion of two photons into a single one at the sum energy. These works have led to the determination of the potential use of those nanoparticles as molecular probes in nonlinear microscopy or substrates for sensing operations. Extension to dielectric nanoparticles has further opened the possibility of new applications in communication for instance. In a second line of research, molecular compounds in the liquid phase have been investigated. These studies comprises supramolecular assemblies like molecular aggregates, biomolecules (amino acids, peptides or proteins) or hybrid nanomaterials formed by nanoparticles and (bio)molecules systems. A specific interest is devoted to systems supported on solid or liquid surfaces (air/solid, liquid/solid or air/liquid and liquid/liquid interfaces). Recently, works have evolved towards the investigation of liquids and molecular organization in liquids at short and long scale.',
    title:
      '"Second Harmonic Generation of Materials at the Nanoscale : \n' +
      'From Incoherent to Coherent Scattering "',
    abstract:
      '<p>\n' +
      'Second Harmonic Generation (SHG) is encountered in its standard form in macroscopic non-centrosymmetric materials where phase matching drives the resulting intensity. At the nanoscale, phase matching no longer dominates and one can access fine details of the material quadratic susceptibility, hence its nanoscale locale structure. Using SHG in a scattering mode, it is possible though to observe whether the loss of coherence is complete or not. The presentation will address this question with two examples.\n' +
      'In a first part, I will discuss incoherent SHG scattering with nanoparticles dispersed in a liquid solvent [1-3]. In the particular case of nanoparticles, I will explore further the delicate balance between composition, size and shape and propose potential applications, notably in sensing [4]. I will further question the possibility of single nanoparticle measurements with spatial control over the nanoparticles, opening new routes in communication for example.\n' +
      'In a second part, I will address the case of the existence of a coherent contribution besides the incoherent one. To this purpose, I will describe the case of liquids, with a special focus on water, and show how short and long range interactions can be accessed using SHG scattering [6]. \n' +
      '</p>\n' +
      '\n' +
      '\n' +
      '<p>References</p>\n' +
      '<ol>\n' +
      '<li>\n' +
      'J. Butet, P.F. Brevet, O.J.F. Martin, Optical Second Harmonic Generation in Plasmonic Nanostructures: From Fundamental Principles to Advanced Applications, ACS Nano, 9 (2015) 10545.\n' +
      '</li>\n' +
      '<li>\n' +
      'L. Bonacina, P.F. Brevet, M. Finazzi, M. Celebrano, Harmonic Generation at the Nanoscale, J. Appl. Phys., 127 (2020) 230901.\n' +
      '</li>\n' +
      '<li>\n' +
      'J. Duboisset, P.F. Brevet, Second Harmonic Scattering Defined Topological Classes for Nano-Objects, J. Phys. Chem. C, 123 (2019) 25303.\n' +
      '</li>\n' +
      '<li>\n' +
      'K. Nadolski, F. Rondepierre, Ch. Jonin, T.M. Goszczyński, K. Matczyszyn, P.F. Brevet, Sensing Copper (II) Ions with Hyper Rayleigh Scattering from Gold Nanoparticles, J. Phys. Chem. C, 127 (2023) 13097\n' +
      '</li>\n' +
      '<li>\n' +
      'J. Duboisset, P.F. Brevet, Salt induced Long-to-Short Range Orientational Transition in Water, Phys. Rev. Lett., 120 (2018) 263001."\n' +
      '</li>\n' +
      '<ol>',
  },
  {
    id: 2,
    name: 'Prof. Artur Erbe',
    affiliation: 'Helmholtz-Zentrum Dresden-Rossendorf, Germany',
    img: ArturErbe,
    topic_id: 1,
    resume:
      'Dr. Artur Erbe, representing the Nanoelectronics Department at the Institute of Ion Beam Physics and Materials Research, Helmholtz-Zentrum.\n' +
      'Department: Nanoelectronics Department\n' +
      'Areas of Expertise:\n' +
      'Molecular Electronics\n' +
      'Nanomagnetism\n' +
      'Nanomechanics\n' +
      'Colloidal Model Systems\n',
    title: 'Conducting nanowires assembled by DNA Origami',
    abstract:
      '<p>The development of ever smaller electronic circuits may greatly benefit from the use of bottomup strategies for generating more efficient and versatile fabrication methods of nanosized elements than by the traditional top-down approach.\n' +
      'One of the most promising approaches is based on the self-recognition of DNA, which allows the construction of arbitrarily shaped nanoobjects with high reproducibility. The formation of conducting nanostructures, however,is\n' +
      'not straightforward because DNA with\n' +
      'arbitrary base sequences conducts charge only under very special conditions. Therefore, one way to introduce electrical conductance in these templates is to place conducting nanoparticles onto the DNA nanostructures \n' +
      'and fuse them using chemical enhancement. Here we demonstrate the formation of conducting nanostructures based on DNA Origami structures. Following one strategy, DNA nanomolds are employed, inside which gold deposition\n' +
      'by site-specific attached seeds starts the metallization. These structures can be subsequently enhanced by internal metal deposition. During this step, the walls of the nanomolds serve as constraints.</p>\n' +
      '\n' +
      '<p>We were able to \n' +
      'prove the metallic nature of these nanostructures by performing temperature-dependent charge transport measurements along the nanostructures [1,2]. The shape of the DNA molds affects the conductance of the \n' +
      'nanostructures [3]. Figure 1. a) DNA Origami-based Au nanostructure contacted by top-down nanocontacts b) I-V characteristics at various temperatures [3]. Using a different approach, the shape of the nanowires \n' +
      'can be controlled. We use DNA-origami templates, which are functionalized on their surface in order to create the desired shapes of the metallic nanostructures [4]. Metallic nanoparticles are attached to the \n' +
      'functional sites and enhanced through electroless deposition to form continuous conductive structures. Temperaturedependent charge transport measurements show that the charge transport mechanisms along these \n' +
      'wires are dominated by hopping effects [5]. Both methods show that the DNA Origami technique serves as a platform for the creation of conducting nanowires with well-defined shapes.\n' +
      '</p>\n' +
      '\n' +
      '<p>References</p>\n' +
      '\n' +
      '<p>\n' +
      '[1] T. Bayrak, S. Helmi, J. Ye, D. Kauert, J. K. Nano, and 2018, DNA-Mold Templated Assembly of Conductive Gold Nanowires, ACS Nano Lett. 18, 2116 (2018).\n' +
      '[2] J. Ye, O. Aftenieva, T. Bayrak, A. Jain, T. A. F. König, A. Erbe, and R. Seidel, Complex Metal Nanostructures with Programmable Shapes from Simple DNA Building Blocks, Advanced Materials 2021, 2100381 (2021).\n' +
      '[3] D. D. Ruiz Arce, S. Jazavandi Ghamsari, A. Erbe, and E. C. Samano, Metallic Nanowires Self-Assembled in QuasiCircular Nanomolds Templated by DNA Origami, International Journal of Molecular Sciences 24, 17 (2023).\n' +
      '[4] B. Teschome, S. Facsko, T. Schoenherr, J. Kerbusch, A. Keller, and A. Erbe, Temperature-Dependent Charge Transport through Individually Contacted DNA Origami-Based Au Nanowires, Langmuir 32, 10159 (2016).\n' +
      '[5] T. Bayrak, A. Martinez-Reyes, D. D. R. Arce, J. Kelling, E. C. Samano, and A. Erbe, Fabrication and TemperatureDependent Electrical Characterization of a C-Shape Nanowire Patterned by a DNA Origami, Scientific Reports 11, 1 (2021).\n' +
      '</p>',
  },
  {
    id: 3,
    name: 'Prof. William M. Gelbart',
    affiliation: 'UCLA, USA',
    img: WilliamGelbart,
    topic_id: 2,
    resume:
      'William (Bill) Gelbart did his undergraduate work at Harvard University (1963-1967) and his PhD work at the University of Chicago (1967-1970), where he worked on the theory of molecular quantum mechanics, spectroscopy, and photophysics in the gas phase. He was an NSF-NATO Postdoctoral Fellow at the University of Paris (1971) and a Miller Institute Postdoctoral Fellow at UC Berkeley (1972), during which time he began theoretical work on light scattering and critical phenomena in simple flui\n' +
      'ds. In 1972 he joined the Chemistry Department at UC Berkeley as Assistant Professor, transferred to UCLA as Associate Professor in 1975, and was promoted in 1979 to Full Professor and in 1999 to Distinguished Professor. Over those 25 years he carried out theoretical work on the statistical physics of complex fluids including liquid crystals, colloidal suspensions, thin films, and micellar, polymer, and nanoparticle solutions. During a sabbatical year in 1999 as Rothschild Professor at the Curie Institute in Paris he became intrigued by viruses as physical objects and focused his theoretical work on how they develop icosahedral and helical symmetry, how they become pressurized upon packaging of their DNA genomes, and how they self-assemble spontaneously around their RNA genomes. Shortly afterwards he started a molecular biology/virology lab, stopped doing theory, and has been concentrating for the past 10 years on in vitro synthesis of virus-like particles for purposes of targeted in vivo delivery of mRNA for vaccines, cell therapeutics, and immunotherapy.',
    title: 'IN VITRO RECONSTITUTED VIRUS-LIKE PARTICLES FOR mRNA GENE DELIVERY',
    abstract:
      '<p>\n' +
      'Viruses have evolved to do essentially one thing – namely, protect their genes until they can deliver them to the right cell. Accordingly, \n' +
      'any gene delivery program should follow the lead of viruses in striving for this level of protection and targeting. \n' +
      'In contrast to the lipid nanoparticles and engineered viruses that are currently the prevailing gene vectors, we synthesize stoichiometrically-precise virus-like particles (VLPs) from in vitro \n' +
      'transcribed mRNA and purified viral capsid protein. \n' +
      '</p>\n' +
      '\n' +
      '<p>The recombinantly-expressed protein comes from one of two plant viruses, and – upon interacting with RNA – forms either a perfectly monodisperse \n' +
      'spherical or cylindrical VLP with a single RNA molecule inside. The mRNA encoding the therapeutic gene of interest is rendered self-amplifying by fusing it to an RNA replicase gene, and the \n' +
      'corresponding VLPs, upon conjugation with targeting antibodies, are shown to result in strong downstream protein expression when transfected into cell culture or injected into mice. I discuss applications\n' +
      ' of this platform to vaccines and to cancer immunotherapy.\n' +
      ' </p>\n',
  },
];
