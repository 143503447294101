import { SideMenu } from '../user-home/SideMenu';
import { LoadingLayer } from '../../LoadingLayer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentImg from './Imagen 1.jpg';

export const fetchUserREceipt = async (setReceipt) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/receipt',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    const data = await response.json();
    console.log(data.Items);
    setReceipt(data.Items);
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const Payments = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [receipt, setReceipt] = useState(null);
  const [requireInvoice, setRequireInvoice] = useState(false);
  const [fiscalSituation, setFiscalSituation] = useState(null);
  const [studiesProof, setStudiesProof] = useState(null);
  const [haveFoodAllergies, setHaveFoodAllergies] = useState(false);
  const [foodAllergies, setFoodAllergies] = useState('');
  const [isStudent, setIsStudent] = useState(false);
  const [userReceipt, setUserReceipt] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [food, setFood] = useState('Chicken Cordon Bleu/Pollo Cordon Bleu');

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage('Loading...');
    fetchUserREceipt(setUserReceipt).then(() => {
      setIsLoading(false);
    });
  }, []);

  const requireInvoiceCheckboxChange = (event) => {
    setRequireInvoice(event.target.checked);
  };

  const foodAllergiesCheckboxChange = (event) => {
    setHaveFoodAllergies(event.target.checked);
  };

  const isStudentCheckboxChange = (event) => {
    setIsStudent(event.target.checked);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const submitReceipt = async () => {
    setIsLoading(true);
    setLoadingMessage('Submitting...');
    const base64Receipt = receipt ? (await fileToBase64(receipt)).split(',')[1] : null;
    const base64FiscalSituation = fiscalSituation
      ? (await fileToBase64(fiscalSituation)).split(',')[1]
      : null;
    const base64StudiesProof = studiesProof
      ? (await fileToBase64(studiesProof)).split(',')[1]
      : null;
    const data = {
      receipt: base64Receipt,
      requireInvoice,
      fiscalSituation: base64FiscalSituation,
      studiesProof: base64StudiesProof,
      food,
      haveFoodAllergies,
      foodAllergies,
      isStudent,
      name,
      email,
    };
    fetch('https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/receipt', {
      method: 'POST',
      headers: {
        'user-token': localStorage.getItem('id_token'),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          navigate('/home');
        }
      })
      .then((data) => {
        console.log(data);
      });
  };

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <div className="snn-abstract-submission-main-grid-wrapper">
        <LoadingLayer isLoading={isLoading} action={loadingMessage} />
        <h1 className="snn-user-home-main-content-header snn-expand-grid-4">Receipt submission</h1>
        {userReceipt.length > 0 ? (
          <>
            <div className="snn-expand-grid-4">
              <h2 className="snn-user-home-main-content-large-semi">Receipt already submitted</h2>
            </div>
          </>
        ) : (
          <>
            <div className="snn-expand-grid-4">
              <h2 className="snn-user-home-main-content-large-semi">Important:</h2>
              <p>
                Attention students: All undergraduate, master&apos;s, and doctoral students whose
                abstracts have been accepted and are presenting them will be entitled to a
                scholarship. On Monday the 8th, the scholarship system will be available on our
                website. Remember, you will need current proof of your student status.
              </p>
              <p>
                <strong>All files must be on pdf format, and have a size lower than 3 MB</strong>
              </p>
              <ul>
                <li>
                  In the case of oral presentation, if you are not registered by May 1st your work
                  might be changed to poster. Please send a letter with confirmation of your
                  presence since this is an in-person presentation.
                </li>
                <li>Payments must be invoiced before April 26.</li>
                <li>
                  It will not be possible to issue invoices for payments from previous months.
                </li>
                <li>
                  If the client has not provided their tax information, the payment will be invoiced
                  as Sale to the General Public with the generic RFC established by the SAT.
                </li>
                <li>
                  Payments not identified at April 26 will be invoiced as Sale to the General Public
                  with the generic RFC established by the SAT.
                </li>
              </ul>

              <p>Bank information</p>
              <table>
                <tr>
                  <td>Bank</td>
                  <td>HSBC</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>ACADEMIA DE CATÁLISIS A. C.</td>
                </tr>
                <tr>
                  <td>Account</td>
                  <td>4 009 999 335</td>
                </tr>
                <tr>
                  <td>Account CLABE </td>
                  <td>021180040099993359</td>
                </tr>
                <tr>
                  <td>SWIFT </td>
                  <td>BIMEMXMM</td>
                </tr>
              </table>

              <p>Concept must be &quot;SNN24 [Person name]&quot; </p>
              <div className="payment-img-wrapper">
                <img src={PaymentImg} alt="Payment information" />
              </div>

              <h2 className="snn-user-home-main-content-large-semi">Importante:</h2>
              <p>
                Atención estudiantes: Todos los estudiantes de pregrado, maestría y doctorado cuyos
                resúmenes hayan sido aceptados y los estén presentando tendrán derecho a una beca.
                El lunes 8, el sistema de becas estará disponible en nuestro sitio web. Recuerda,
                necesitarás una prueba actual de tu condición de estudiante.
              </p>
              <p>
                <strong>
                  Todos los archivos deben estar en formato pdf y tener un tamaño menor a 3 MB
                </strong>
              </p>
              <ul>
                <li>
                  En caso de presentación oral, si no está registrado antes del 1 de mayo, su
                  trabajo podría cambiarse a póster. Por favor, envíe una carta con la confirmación
                  de su presencia ya que se trata de una presentación presencial.
                </li>
                <li>Los pagos deben ser facturados antes del 26 de abril.</li>
                <li>No será posible emitir facturas por pagos de meses anteriores.</li>
                <li>
                  Si el cliente no ha proporcionado su información fiscal, el pago se facturará como
                  Venta al Público en General con el RFC genérico establecido por el SAT.
                </li>
                <li>
                  Los pagos no identificados al al 26 de abril se facturarán como Venta al Público
                  en General con el RFC genérico establecido por el SAT.
                </li>
              </ul>

              <p>Información del banco</p>
              <table>
                <tr>
                  <td>Banco</td>
                  <td>HSBC</td>
                </tr>
                <tr>
                  <td>Nombre</td>
                  <td>ACADEMIA DE CATÁLISIS A. C.</td>
                </tr>
                <tr>
                  <td>Cuenta</td>
                  <td>4 009 999 335</td>
                </tr>
                <tr>
                  <td>Cuenta CLABE </td>
                  <td>021180040099993359</td>
                </tr>
              </table>

              <p>El concepto debe ser &quot;SNN24 [Person name]&quot; </p>
              <div className="payment-img-wrapper">
                <img src={PaymentImg} alt="Payment information" />
              </div>
            </div>
            <div className="snn-panel-form-wrapper snn-expand-grid-2">
              <h2 className="snn-user-home-main-content-large-semi">Invoice information </h2>
              <div className="snn-form-group">
                <label>Receipt / Recibo de pago</label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => setReceipt(e.target.files[0])}
                />
              </div>
              <div className="snn-form-group">
                <label>Name for receipt / Nombre para recibo</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
              </div>
              <div className="snn-form-group">
                <label>Email to send receipt / Correo para enviar recibo</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" />
              </div>
              <div className="snn-form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={requireInvoice}
                    onChange={requireInvoiceCheckboxChange}
                  />
                  Do you need invoice? / ¿Necesitas factura?
                </label>
              </div>
              {requireInvoice && (
                <div className="snn-form-group">
                  <label>Proof of fiscal situation / Carta de situación fiscal</label>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setFiscalSituation(e.target.files[0])}
                  />
                </div>
              )}
              <div className="snn-form-group">
                <label>
                  <input type="checkbox" checked={isStudent} onChange={isStudentCheckboxChange} />
                  Are you student? / ¿Eres estudiante?
                </label>
              </div>
              {isStudent && (
                <div className="snn-form-group">
                  <label>
                    Student proof status (Photocopy of your dated student ID (with current date)) /
                    Comprobante de estudios (Copia de la credencial de estudiante con fecha
                    reciente)
                  </label>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setStudiesProof(e.target.files[0])}
                  />
                </div>
              )}
            </div>
            <div className="snn-panel-form-wrapper snn-expand-grid-2-2">
              <h2 className="snn-user-home-main-content-large-semi">Food preferences</h2>
              <div className="snn-form-group">
                <select
                  id="degree"
                  name="degree"
                  value={food}
                  onChange={(e) => setFood(e.target.value)}
                >
                  <option selected value="Chicken Cordon Bleu/Pollo Cordon Bleu">
                    Chicken Cordon Bleu/Pollo Cordon Bleu
                  </option>
                  <option name="degree" value="Shrimp with fillet/Camaron con Filete">
                    Shrimp with fillet/Camaron con Filete
                  </option>
                  <option name="degree" value="Vegetarian/Vegetariano">
                    Vegetarian/Vegetariano
                  </option>
                </select>
              </div>
              <div className="snn-form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={haveFoodAllergies}
                    onChange={foodAllergiesCheckboxChange}
                  />
                  Food allergies? / ¿Alergias alimentarias?
                </label>
              </div>
              {haveFoodAllergies && (
                <div className="snn-form-group">
                  <label>
                    Please specify which allergies you have in the space provided below / Por favor
                    especifica a qué eres alergico
                  </label>
                  <input
                    value={foodAllergies}
                    onChange={(e) => setFoodAllergies(e.target.value)}
                    type="text"
                  />
                </div>
              )}
            </div>
            <div className="snn-expand-grid-4">
              <div className="snn-submit-abstract-buttons-wrapper flex-start">
                <button onClick={submitReceipt} className="button">
                  Submit receipt
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
