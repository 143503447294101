// import { AbstractSubmission } from './AbstractSubmission';
import { SideMenu } from '../user-home/SideMenu';
import { AbstractSubmission } from './AbstractSubmission';

export const AbstractSubmissionWrapper = () => {
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <AbstractSubmission />
    </div>
  );
};
