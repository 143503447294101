import { useEffect, useState } from 'react';
import { useJwt } from 'react-jwt';
import { Logout } from '../../App';
//import {useJwt} from "react-jwt";

export const useIsValidToken = () => {
  // eslint-disable-next-line no-unused-vars
  const [isValid, setIsValid] = useState(false);
  const access_token = localStorage.getItem('access_token');
  const { decodedToken, isExpired } = useJwt(access_token);

  useEffect(() => {
    if (access_token) {
      if (isExpired) {
        Logout();
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  }, [decodedToken, isExpired]);

  return [isValid];
};
