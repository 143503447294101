import { SideMenu } from '../user-home/SideMenu';
import { useEffect, useState } from 'react';
import { useIsValidToken } from '../../hooks/TokenHook';
import { Logout } from '../../../App';
import { fetchProfileData } from '../user-home/UserHome';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});

  const isValidToken = useIsValidToken();

  useEffect(() => {
    if (isValidToken) {
      fetchProfileData(setProfile);
    } else {
      Logout();
      navigate('/');
    }
  }, []);
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <div className="snn-user-home-main-content">
        <h1 className="snn-user-home-main-content-header">User profile</h1>
        <div className="snn-panel-form-wrapper snn-expand-grid-2">
          <h2 className="snn-user-home-main-content-large-semi">User data</h2>
          <p>
            <strong>Name:</strong> {profile ? profile.name : null}
          </p>
          <p>
            <strong>Email:</strong> {profile ? profile.user_email : null}
          </p>
          <p>
            <strong>Country:</strong> {profile ? profile.country : null}
          </p>
          <p>
            <strong>State:</strong> {profile ? profile.state : null}
          </p>
          <hr />
          <p>
            <strong>Institution:</strong> {profile ? profile.institution : null}
          </p>
          <p>
            <strong>Grade:</strong> {profile ? profile.grade : null}
          </p>
        </div>
      </div>
    </div>
  );
};
