import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { Footer } from '../../footer/Footer';
import { WorkshopsList } from '../../../data/WorkshopsList';
import './Workshops.css';

export const Workshops = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <section className="snn-page-section">
          <h1 className="snn-section-title">Workshops</h1>
          <p>
            SNN2024 is pleased to announce the opening of its workshop session scheduled for Monday,
            May 13th. The cost per workshop is $200 MXN in cash, and no invoices will be issued—only
            proof of payment. Payment must be made on Monday, May 13th, prior to attending the
            workshop. Participants may select only one workshop, as space is limited. To choose your
            workshop, please fill out the following{' '}
            <strong>
              <a
                target="_blank"
                rel="noreferrer"
                className="email-link"
                href="https://forms.gle/MGxviBW1xEaiqzYDA"
              >
                Google form
              </a>
            </strong>
            .
          </p>
        </section>
        <div className="snn-page-section">
          {WorkshopsList.map((workshop, index) => {
            return (
              <div key={index} className="snn-panel-form-wrapper workshop-card">
                <div className="workshop-card-img-wrapper">
                  <img src={workshop.img} alt={workshop.name} />
                </div>
                <div className="workshop-card-description-wrapper">
                  <h2 className="snn-user-home-main-content-header">{workshop.name}</h2>
                  <p>{workshop.summary}</p>
                  <p>{workshop.institution}</p>
                  <div>
                    <p className="snn-user-home-main-content-large-semi">Instructors</p>
                    {workshop.instructors.map((instructor, index) => {
                      return <p key={index}>{instructor}</p>;
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};
