import { SideMenu } from '../user-home/SideMenu';
import { AcceptanceLetters } from './AcceptanceLetters';

export const AcceptanceLettersWrapper = () => {
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <AcceptanceLetters />
    </div>
  );
};
