import Marriot from './marriot.jpg';
import './Hotel.css';

export const HotelMarriot = () => {
  return (
    <>
      <section className="snn-page-section snn-hotel-wrapper">
        <div className="snn-hotel-image">
          <img src={Marriot} alt="Hotel img" />
        </div>
        <div className="snn-hotel-description">
          <h2 className="snn-title">City Express by Marriott Ensenada</h2>
          <p>End of reservation: May 4, 2024.</p>
          <p>Marriott Hotels offers its special group rate:</p>
          <ul>
            <li>
              Address: Boulevard Costero B1-A2 manzana 29, Carlos Pacheco Ensenada, Baja California
              22890
            </li>
            <li>Cost: $1,350 MXN - $1,470 MXN per night</li>
            <li>Single Room with Queen bed for 1 or 2 people, $1,525.50 MXN.</li>
            <li>
              Double Room with 2 Double beds for 2 people and up to 2 children under 12 years old,
              $1,661.10 MXN.
            </li>
            <li>
              Triple Room with 2 Double beds for 3 adults and up to 1 child under 12 years old.
              $2,000.10 MXN.
            </li>
            <li>Free breakfast included</li>
            <li>
              To make a reservation use this{' '}
              <a
                className="snn-email-link"
                href="https://www.marriott.com/es/event-reservations/reservation-link.mi?id=1712088822458&key=GRP&app=resvlink"
              >
                link
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
