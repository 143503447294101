import { SideMenu } from '../user-home/SideMenu';
import { RegisteredAbstracts } from './RegisteredAbstracts';

export const RegisteredAbstractWrapper = () => {
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <RegisteredAbstracts />
    </div>
  );
};
