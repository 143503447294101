import './FormGroup.css';
import PropTypes from 'prop-types';

export const FormGroup = ({ label, value, setValue, name }) => {
  return (
    <div className="snn-form-group">
      <label>{label}</label>
      <input name={name} type="text" value={value} onChange={(e) => setValue(e)} />
    </div>
  );
};

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
