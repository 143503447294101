import PropTypes from 'prop-types';

export const Option = ({ option }) => {
  return (
    <div className="snn-top-menu-option">
      <p>{option.label}</p>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.any,
};
