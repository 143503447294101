import { ReactComponent as LoadingSVG } from '../assets/only_logo.svg';
import './LoadingLayer.css';
import PropTypes from 'prop-types';

export const LoadingLayer = ({ isLoading, action }) => {
  return (
    <div className={'loading-layer ' + (isLoading ? '' : 'hide')}>
      <LoadingSVG />
      <div className="waviy">
        {action.split('').map((letter, index) => (
          <span key={index} style={{ '--i': index }}>
            {letter}
          </span>
        ))}
      </div>
    </div>
  );
};

LoadingLayer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
};
