import './FormGroup.css';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { useEffect, useState } from 'react';
import draftToHtml from 'draftjs-to-html';

export const FormGroupTextArea = ({ label, setValue, state }) => {
  const [editorState, setEditorState] = useState(state);
  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue(html);
  }, [editorState]);
  return (
    <div className="snn-form-group">
      <label>{label}</label>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={setEditorState}
        placeholder="Write here..."
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
          },
          history: { inDropdown: false },
        }}
      />
    </div>
  );
};

FormGroupTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(EditorState).isRequired,
};
