import './UserHome.css';
import { UserMenu } from './UserMenu';
import { SpeakersList } from '../../../data/speakersList';
import AbstractImg from './abstract.png';
import { KeynoteList } from '../../../data/KeynotesList';
import { TopUserMenu } from './TopUserMenu';
import { SideMenu } from './SideMenu';
import { Link, useNavigate } from 'react-router-dom';
import Alberto from '../../../assets/images/speakers/AlbertoGuijosaC.jpg';
// eslint-disable-next-line no-unused-vars
import { TopicsList } from '../../../data/TopicsList';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Logout } from '../../../App';
import { ReactComponent as TimeLine } from './timeline.svg';
import { LoadingLayer } from '../../LoadingLayer';
import { SpeakerModal } from '../speakers/SpeakersWrapper';
import { useJwt } from 'react-jwt';

export const fetchProfileData = async (setProfile) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/profiles',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    if (response.status === 401) {
      return response.status;
    }
    const data = await response.json();
    setProfile(data.Items[0]);
    return response.status;
  } catch (error) {
    return error;
  }
};

export const fetchUserAbstractData = async (setAbstracts) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/abstract',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    if (response.status === 401) {
      return response.status;
    }
    const data = await response.json();
    setAbstracts(data.Items);
    console.log(data.Items);
    return response.status;
  } catch (error) {
    return error;
  }
};

export const UserHome = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [abstracts, setAbstracts] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const access_token = localStorage.getItem('id_token');
  const { decodedToken } = useJwt(access_token);

  useEffect(() => {
    if (decodedToken) {
      if (decodedToken['cognito:groups'] && decodedToken['cognito:groups'].includes('admin')) {
        navigate('/admin');
      } else if (
        decodedToken['cognito:groups'] &&
        decodedToken['cognito:groups'].includes('coordniator')
      ) {
        navigate('/coordinator');
      } else if (
        decodedToken['cognito:groups'] &&
        decodedToken['cognito:groups'].includes('revisor') &&
        !decodedToken['cognito:groups'].includes('coordniator')
      ) {
        navigate('/revisor');
      }
    }
  }, [decodedToken]);

  const specialGuestList = [
    {
      id: 0,
      name: 'Prof. Alberto Güijosa',
      affiliation: 'ICN-UNAM, Mexico',
      img: Alberto,
      topic_id: null,
      resume:
        'Alberto Güijosa is a Professor at the Institute of Nuclear Sciences of the National Autonomous University of Mexico, where he is additionally the Director of the Graduate Program in Physical Sciences. He got his Ph.D. in 1999 at Princeton University, specializing in string theory. For his work in the holographic (or AdS/CFT) correspondence, he was awarded the 2010 Research Prize of the Mexican Academy of Sciences.',
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectSpeaker = (speakerId, list) => {
    console.log(speakerId, list);
    const speaker = list.find((speaker) => speaker.id === speakerId);
    setSelectedSpeaker(speaker);
    setIsModalOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage('Loading');
    fetchUserAbstractData(setAbstracts).then(() => {
      setIsLoading(false);
    });
    fetchProfileData(setProfile).then((responseStatus) => {
      console.log(responseStatus);
      if (responseStatus === 401) {
        Logout();
        navigate('/');
      }
      setIsLoading(false);
    });
  }, []);

  const logoutButton = () => {
    Logout();
    navigate('/');
  };

  return (
    <div className="snn-user-home-main-wrapper">
      <LoadingLayer isLoading={isLoading} action={loadingMessage} />
      <div className={isModalOpen ? '' : 'hide'}>
        <SpeakerModal speaker={selectedSpeaker} closeModal={closeModal} />
      </div>
      <SideMenu />

      <div className="snn-user-home-main-content">
        <TopUserMenu name={profile ? profile.name : null} />
        <h1 className="snn-user-home-main-content-header hide-on-desktop">Welcome</h1>
        <h2 className="snn-user-home-main-content-large-semi hide-on-desktop">
          {profile ? profile.name : null}
        </h2>
        <div className="snn-user-home-main-content-grid">
          <div className="snn-user-home-main-content-submit-ribbon">
            <div className="snn-user-home-main-content-submit-ribbon-content">
              <p className="snn-user-home-main-content-small-text gold-text">
                Review Feb, 19 - Mar, 14
              </p>
              <p className="snn-user-home-main-content-large-semi">Under review</p>
              {/*<Link to="/abstracts/submit">*/}
              {/*  <div className="snn-user-home-main-content-submit-ribbon-button">*/}
              {/*    Submit now <span className="material-symbols-outlined">arrow_right_alt</span>*/}
              {/*  </div>*/}
              {/*</Link>*/}
            </div>
            <div className="snn-ribbon-img-wrapper">
              <img src={AbstractImg} alt="abstract" />
            </div>
          </div>
          <section className="snn-user-home-section snn-user-home-abstract-section">
            <p className="snn-user-homa-main-content-text">Registered abstracts</p>
            <div className="snn-user-home-abstract-container">
              {abstracts ? (
                abstracts.map((abstract) => (
                  <Link key={abstract.title} to={`/abstracts/${abstract.id}`}>
                    <div
                      className={
                        'snn-user-home-abstract-wrapper ' +
                        {
                          'Advanced materials and physics of nanostructures':
                            ' snn-user-home-abstract-advanced',
                          Bionanotechnology: ' snn-user-home-abstract-bio',
                          Nanophotonics: ' snn-user-home-nanophotonic',
                          'Physical-chemistry of nanomaterials and nanocatalysis':
                            ' snn-user-home-catalysis',
                          'Tech innovation in nanoscience': ' snn-user-home-innovation',
                        }[abstract.category] +
                        (abstract.category === 'Advanced ' ? 'snn-user-home-abstract-advanced' : '')
                      }
                    >
                      <div className="snn-user-home-abstract-wrapper-text">
                        <p className="snn-user-home-main-content-small-text">{abstract.status}</p>
                        <p className="snn-user-home-main-content-large-semi">{abstract.title}</p>
                      </div>
                      <div className="snn-user-home-abstract-image-wrapper">
                        {abstract ? (
                          <img
                            src={TopicsList.filter((t) => t.name === abstract.category)[0].src_bg}
                            alt="Bionanotecnology"
                          />
                        ) : null}
                      </div>
                      {/*<div className="snn-user-home-wrapper-edit-button">*/}
                      {/*  <Link to={'/abstracts/edit/' + abstract.id}>*/}
                      {/*    <div className="snn-abstract-registered-card-button">*/}
                      {/*      Edit <span className="material-symbols-outlined">edit</span>*/}
                      {/*    </div>*/}
                      {/*  </Link>*/}
                      {/*</div>*/}
                    </div>
                  </Link>
                ))
              ) : (
                <p>No abstract registered yet</p>
              )}
            </div>
          </section>
          <section className="snn-timeline-wrapper snn-panel-form-wrapper ">
            <h1 className="snn-user-home-main-content-header">SNN24 Timeline</h1>
            <TimeLine />
          </section>
          <section className="plenary-grid">
            <p className="snn-user-homa-main-content-text">Speakers</p>
            <div className="snn-user-home-speakers-wrapper">
              {SpeakersList.map((speaker) => (
                <div
                  className="snn-user-home-main-speaker-wrapper"
                  key={speaker.name}
                  onClick={() => selectSpeaker(speaker.id, SpeakersList)}
                >
                  <img
                    className="snn-user-home-main-speaker-wrapper-speaker"
                    src={speaker.img}
                    alt={speaker.name}
                  />
                  <img
                    className="snn-user-home-main-speaker-wrapper-topic"
                    src={TopicsList.filter((t) => t.id === speaker.topic_id)[0].src_shield}
                  />
                </div>
              ))}
            </div>
          </section>
          <section className="plenary-grid">
            <p className="snn-user-homa-main-content-text">Keynotes</p>
            <div className="snn-user-home-speakers-wrapper">
              {KeynoteList.map((speaker) => (
                <div
                  className="snn-user-home-main-speaker-wrapper"
                  key={speaker.name}
                  onClick={() => selectSpeaker(speaker.id, KeynoteList)}
                >
                  <img
                    className="snn-user-home-main-speaker-wrapper-speaker"
                    src={speaker.img}
                    alt={speaker.name}
                  />
                  <img
                    className="snn-user-home-main-speaker-wrapper-topic"
                    src={TopicsList.filter((t) => t.id === speaker.topic_id)[0].src_shield}
                  />
                </div>
              ))}
            </div>
          </section>
          <section className="plenary-grid">
            <p className="snn-user-homa-main-content-text">Special Guest</p>
            <div className="snn-user-home-speakers-wrapper">
              <div
                className="snn-user-home-main-speaker-wrapper"
                onClick={() => selectSpeaker(0, specialGuestList)}
              >
                <img
                  className="snn-user-home-main-speaker-wrapper-speaker"
                  src={Alberto}
                  alt="Alberto"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
      <UserMenu logoutButton={logoutButton} />
    </div>
  );
};
