import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Logout } from '../../../App';
import { fetchAbstractById } from '../abstract/ViewAbstract';
import './AcceptanceLetter.css';
import { AcceptanceLetterComponent } from './AcceptanceLetterComponent';
import { SideMenu } from '../user-home/SideMenu';

export const AcceptanceLetterDiv = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [abstract, setAbstract] = useState();

  useEffect(() => {
    fetchAbstractById(id, setAbstract).then((result) => {
      console.log(abstract);
      if (result.id === undefined) {
        Logout();
        navigate('/');
      }
    });
  }, []);

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <div className="snn-abstract-submission-main-grid-wrapper">
        <div className="snn-expand-grid-4 acceptance-letter-grid-wrapper">
          <div className="acceptance-letter">
            {abstract ? (
              <AcceptanceLetterComponent abstract={abstract} />
            ) : (
              <div>
                <h1>Loading...</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
