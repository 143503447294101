import './footer.css';
import Facebook from './facebook.png';
import FooterLogo from './FooterLogo';
import { TopicsList } from '../../data/TopicsList';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="snn-footer-wrapper">
      <div className="snn-footer-section-wrapper">
        <div className="snn-footer-section">
          <h2 className="snn-section-title">Topics</h2>
          {TopicsList.map((t) => (
            <Link key={t.id} to={'/topics/' + t.id}>
              <p className="snn-section-item">{t.name}</p>
            </Link>
          ))}
        </div>
        <div className="snn-footer-section">
          <h2 className="snn-section-title">Symposium</h2>
          <p className="snn-section-item">About</p>
          <p className="snn-section-item">Privacy statement</p>
        </div>
        <div className="snn-footer-section media">
          <h2 className="snn-section-title">Social media</h2>
          <a target="_blank" href="https://www.facebook.com/simposiocnyn" rel="noopener noreferrer">
            <img src={Facebook} alt="Facebook logo" />
          </a>
          <h2 className="snn-section-title snn-margin-top">Contact us</h2>
          <p>nnsymposium@ens.cnyn.unam.mx</p>
        </div>
        <div className="snn-footer-section media"></div>
      </div>
      <div className="snn-footer-logo-wrapper">
        <FooterLogo
          className="footer-logo-wrapper-svg"
          alt="Symposium of Nanoscience and Nanomaterials logo"
        />
      </div>
    </footer>
  );
};
