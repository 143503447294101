import { LoadingLayer } from '../../LoadingLayer';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Admin.css';
import { SideMenuAdmin } from './SideMenuAdmin';

export const fetchAdminReceipts = async (setReceipts) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/admin/receipts',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    const data = await response.json();
    console.log(data);
    setReceipts(data.Items);
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const AdminReceiptView = () => {
  const [receipts, setReceipts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState('Poster');

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage('Loading');
    fetchAdminReceipts(setReceipts).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenuAdmin />
      <div className="snn-abstract-submission-main-grid-wrapper">
        <LoadingLayer isLoading={isLoading} action={loadingMessage} />

        <section className="snn-expand-grid-4">
          <h1 className="snn-user-home-main-content-header">Receipts</h1>
          <div>
            {receipts &&
              receipts.map((receipt) => (
                <Link key={receipt.id + receipt.title} to={`/receipts/${receipt.id}`}>
                  <div className="receipt-card">
                    <h2 className="snn-user-home-main-content-header">{receipt.name}</h2>
                    <p>
                      <strong>Email:</strong> {receipt.email}
                    </p>
                    <p>
                      <strong>Status:</strong> {receipt.status}
                    </p>
                    <p>{receipt.isStudent}</p>
                    <p>
                      <strong>Is vegetarian:</strong> {receipt.isVegetarian ? 'Yes' : 'No'}
                    </p>
                    <p>
                      <strong>Is student:</strong> {receipt.isStudent ? 'Yes' : 'No'}
                    </p>
                    <p>
                      <strong>Have food allergies?</strong>{' '}
                      {receipt.haveFoodAllergies ? 'Yes' : 'No'}
                    </p>
                    {receipt.haveFoodAllergies && (
                      <p>
                        <strong>Food allergies:</strong> {receipt.foodAllergies}
                      </p>
                    )}
                    <p>
                      <strong>Created at:</strong> {receipt.created_at}
                    </p>
                  </div>
                </Link>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};
