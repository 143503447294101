import { TopicsList } from '../../../data/TopicsList';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useIsValidToken } from '../../hooks/TokenHook';
import { Logout } from '../../../App';
import { LoadingLayer } from '../../LoadingLayer';

export const fetchUserAcceptanceLettersData = async (setAbstracts) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/abstract',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    if (response.status === 401) {
      return response.status;
    }
    const data = await response.json();
    const acceptanceLetters = data.Items.filter(
      (abstract) => abstract.accepted === 'oral' || abstract.accepted === 'poster',
    );
    setAbstracts(acceptanceLetters);
    console.log(data.Items);
    return response.status;
  } catch (error) {
    return error;
  }
};

export const AcceptanceLetters = () => {
  const navigate = useNavigate();
  const [abstracts, setAbstracts] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const isValidToken = useIsValidToken();

  useEffect(() => {
    if (isValidToken) {
      setIsLoading(true);
      setLoadingMessage('Loading');
      fetchUserAcceptanceLettersData(setAbstracts).then(() => setIsLoading(false));
    } else {
      Logout();
      navigate('/');
    }
  }, []);

  return (
    <div className="snn-abstract-submission-main-grid-wrapper">
      <LoadingLayer isLoading={isLoading} action={loadingMessage} />
      <div className="snn-expand-grid-4">
        <h1 className="snn-user-home-main-content-header">Acceptance letters</h1>
        <div>
          {abstracts ? (
            abstracts.map((abstract) => (
              <Link key={abstract.title} to={`/acceptance-letters/${abstract.id}`}>
                <div
                  className={
                    'snn-abstract-registered-card' +
                    {
                      'Advanced materials and physics of nanostructures':
                        ' snn-user-home-abstract-advanced',
                      Bionanotechnology: ' snn-user-home-abstract-bio',
                      Nanophotonics: ' snn-user-home-nanophotonic',
                      'Physical-chemistry of nanomaterials and nanocatalysis':
                        ' snn-user-home-catalysis',
                      'Tech innovation in nanoscience': ' snn-user-home-innovation',
                    }[abstract.category] +
                    (abstract.status === 'Submitted'
                      ? ' snn-abstract-registered-card-submitted'
                      : ' snn-abstract-registered-card-draft')
                  }
                >
                  <div className="snn-abstract-registered-card-text">
                    <div style={{ flex: 1 }}>
                      <p className="snn-user-home-main-content-large-semi">{abstract.title}</p>
                      <p className="snn-user-home-main-content-small-text">
                        Accepted as: {abstract.accepted}
                      </p>
                    </div>
                    <img src={TopicsList.filter((t) => t.name === abstract.category)[0].src_bg} />
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div>
              <h1 className="snn-user-home-main-content-header">No acceptance letter found</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
