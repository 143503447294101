import { useState } from 'react';

export const useInitial = () => {
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState({
    loading: false,
    error: false,
  });

  return status;
};
