import { Option } from './Option';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const OptionsWrapper = ({ options }) => {
  return (
    <div className="snn-top-menu-options-wrapper">
      <div className="snn-top-menu-options">
        {options.map((o) => (
          <Link key={o.label} to={o.link}>
            {' '}
            <Option option={o} />{' '}
          </Link>
        ))}
        <a href="https://nnsymposium.auth.us-west-1.amazoncognito.com/login?client_id=2rghd4qrs6co16oh5e3t685km3&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://snnsymposium.com">
          {' '}
          <Option option={{ label: 'Login/Register' }} />{' '}
        </a>
      </div>
    </div>
  );
};

OptionsWrapper.propTypes = {
  options: PropTypes.any,
};
