import { FormGroup } from '../../forms/FormGroup';
import './AbstractSubmission.css';
import { useEffect, useRef, useState } from 'react';
import { EditorState } from 'draft-js';
import parse from 'html-react-parser';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormGroupTextArea } from '../../forms/FormGroupTextArea';
import { AuthorForm } from '../../forms/AuthorForm';
import { TopicsList } from '../../../data/TopicsList';
import { LoadingLayer } from '../../LoadingLayer';
import { useNavigate } from 'react-router-dom';

export const submitAbstractRequest = async (abstract) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/abstract',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-token': localStorage.getItem('id_token'),
        },
        body: JSON.stringify(abstract),
      },
    );
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const saveAbstractRequest = async (abstract) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/abstract/draft',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-token': localStorage.getItem('id_token'),
        },
        body: JSON.stringify(abstract),
      },
    );
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const AbstractSubmission = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const fileInputRef = useRef();
  const [modalShwon, setModalShwon] = useState(false);
  const [condition, setCondition] = useState(
    'I agree to my presentation being broadcast (in real time or on demand on the internet or other digital media) without limitation. (RECOMMENDED)',
  );

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImage(imageUrl);

    const img = new Image();

    img.src = imageUrl;

    img.onload = () => {
      setImageWidth(img.naturalWidth);
      setImageHeight(img.naturalHeight);
    };
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const [acknowledgments, setAcknowledgments] = useState('');
  const [references, setReferences] = useState(['']);
  const [imageFooter, setImageFooter] = useState('');
  const [presentationType, setPresentationType] = useState('Poster');

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const [background, setBackground] = useState(() => EditorState.createEmpty());
  const [methodology, setMethodology] = useState(() => EditorState.createEmpty());
  const [conclusion, setConclusion] = useState(() => EditorState.createEmpty());
  const [results, setResults] = useState(() => EditorState.createEmpty());
  const [authors, setAuthors] = useState([
    { name: '', lastName: '', degree: '', email: '', affiliations: [], correspondingAuthor: true },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const [affiliationsString, setAffiliationString] = useState('');
  const [authorsString, setAuthorString] = useState('');
  const [referencesString, setReferencesString] = useState('');
  const [topic, setTopic] = useState(TopicsList[0].name);

  const showModal = () => {
    setModalShwon(true);
  };

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    console.log('Moving up');
    setAuthors((prevAuthors) => {
      let newAuthors = [...prevAuthors];
      newAuthors[index] = newAuthors.splice(index - 1, 1, newAuthors[index])[0];
      return newAuthors;
    });
  };

  const saveAbstract = () => {
    setIsLoading(true);
    setLoadingMessage('Sending abstract');
    if (fileInputRef.current.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(fileInputRef.current.files[0]);
      reader.onloadend = () => {
        const base64Image = reader.result.split(',')[1];
        setImage(true);
        setLoadingMessage('Saving abstract');
        let abstract = {
          title: title,
          authors: authors,
          affiliations: authors.affiliations,
          background: background,
          results: results,
          methodology: methodology,
          conclusion: conclusion,
          category: topic,
          acknowledgments: acknowledgments,
          references: references,
          presentation_type: presentationType,
          condition: condition,
          image: base64Image,
          image_width: imageWidth,
          image_height: imageHeight,
          image_footer: imageFooter,
        };
        saveAbstractRequest(abstract).then(() => {
          setIsLoading(false);
          navigate('/home');
        });
      };
    } else {
      let abstract = {
        title: title,
        authors: authors,
        affiliations: authors.affiliations,
        background: background,
        results: results,
        methodology: methodology,
        conclusion: conclusion,
        category: topic,
        acknowledgments: acknowledgments,
        references: references,
        presentation_type: presentationType,
        condition: condition,
        image: null,
        image_width: 0,
        image_height: 0,
        image_footer: imageFooter,
      };
      saveAbstractRequest(abstract).then(() => {
        setIsLoading(false);
        navigate('/home');
      });
    }
  };

  const submitAbstract = () => {
    setIsLoading(true);
    setLoadingMessage('Sending abstract');
    if (fileInputRef.current.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(fileInputRef.current.files[0]);
      reader.onloadend = () => {
        const base64Image = reader.result.split(',')[1];
        let abstract = {
          title: title,
          authors: authors,
          affiliations: authors.affiliations,
          background: background,
          results: results,
          methodology: methodology,
          conclusion: conclusion,
          category: topic,
          acknowledgments: acknowledgments,
          references: references,
          presentation_type: presentationType,
          condition: condition,
          image: base64Image,
          image_width: imageWidth,
          image_height: imageHeight,
          image_footer: imageFooter,
        };
        console.log(abstract);
        submitAbstractRequest(abstract).then(() => {
          navigate('/home');
        });
      };
    } else {
      let abstract = {
        title: title,
        authors: authors,
        affiliations: authors.affiliations,
        background: background,
        results: results,
        methodology: methodology,
        conclusion: conclusion,
        category: topic,
        acknowledgments: acknowledgments,
        references: references,
        presentation_type: presentationType,
        condition: condition,
        image: null,
        image_width: 0,
        image_height: 0,
        image_footer: imageFooter,
      };
      console.log(abstract);
      submitAbstractRequest(abstract).then(() => {
        navigate('/home');
      });
    }
  };

  const moveDown = (index) => {
    if (index === authors.length - 1) {
      return;
    }
    console.log('Moving down');
    setAuthors((prevAuthors) => {
      let newAuthors = [...prevAuthors];
      newAuthors[index] = newAuthors.splice(index + 1, 1, newAuthors[index])[0];
      console.log(newAuthors);
      return newAuthors;
    });
  };

  // Function to remove author by index
  const removeAuthor = (index) => {
    let newAuthors = [...authors];
    newAuthors.splice(index, 1);
    setAuthors(newAuthors);
  };

  useEffect(() => {
    let tempAuthorString = '';
    let tempAffiliationString = '';

    let affiliations = Array.from(new Set(authors.map((author) => author.affiliations).flat()));

    for (const iAffiliation in affiliations) {
      if (tempAffiliationString.length > 0) {
        tempAffiliationString += '<br/> ';
      }
      tempAffiliationString += `<sup>${parseInt(iAffiliation) + 1}</sup> ${
        affiliations[iAffiliation]
      }`;
    }

    for (const author of authors) {
      // Add ", " to tempAuthorString if is not the first author
      if (tempAuthorString.length > 0) {
        tempAuthorString += ', ';
      }
      if (author.name === undefined) {
        continue;
      }
      tempAuthorString += `${author.lastName} ${
        author.name.length > 0 ? author.name[0] + '.' : ''
      }`;
      // Add superscript to author
      if (author.affiliations) {
        tempAuthorString += `<sup>${author.affiliations
          .map((affiliation) => {
            return affiliations.indexOf(affiliation) + 1;
          })
          .join(', ')}</sup>`;
      }
    }
    setAuthorString('<p>' + tempAuthorString + '</p>');
    setAffiliationString(tempAffiliationString);
  }, [authors]);

  const addReference = () => {
    let newField = '';
    setReferences([...references, newField]);
  };

  const removeReference = (index) => {
    let newFields = [...references];
    newFields.splice(index, 1);
    setReferences(newFields);
  };

  const getReferencesString = (references) => {
    const referencesArray = references; //references.split(';');
    let temp = '';
    for (const aff in referencesArray) {
      if (aff > 0) {
        temp += '<br/>';
      }
      temp += `[${parseInt(aff) + 1}] ${referencesArray[aff]}`;
    }
    return `${temp}`;
  };

  const addAuthor = () => {
    let newField = {
      name: '',
      lastName: '',
      degree: '',
      email: '',
      affiliations: [],
      correspondingAuthor: false,
    };
    setAuthors([...authors, newField]);
  };

  useEffect(() => setReferencesString(getReferencesString(references)), [references]);

  function createMarkup(html) {
    return {
      __html: html,
    };
  }

  const handleClickImage = (mouseDownEvent) => {
    if (mouseDownEvent.target.tagName === 'IMG') {
      const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
      let newSize = { x: 0, y: 0 };
      let aspectRatio = mouseDownEvent.target.offsetWidth / mouseDownEvent.target.offsetHeight;
      const startSize = {
        x: mouseDownEvent.currentTarget.offsetWidth,
        y: mouseDownEvent.currentTarget.offsetHeight,
      };
      const onMouseMove = (mouseMoveEvent) => {
        newSize = {
          x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
          y: (startSize.x - startPosition.x + mouseMoveEvent.pageX) / aspectRatio,
        };
        if (newSize.x > 0) {
          mouseDownEvent.target.style.width = `${newSize.x}px`;
          mouseDownEvent.target.style.height = `${newSize.y}px`;
          mouseDownEvent.target.style.outline = 'none';
          setImageHeight(newSize.y);
          setImageWidth(newSize.x);
        }
      };
      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', onMouseMove);
        document.body.removeEventListener('mouseup', onMouseUp);
      };
      document.body.addEventListener('mousemove', onMouseMove);
      document.body.addEventListener('mouseup', onMouseUp, { once: true });
      mouseDownEvent.target.style.outline = '1px solid red';
      mouseDownEvent.target.setAttribute('draggable', false);
    }
  };

  return (
    <div className="snn-abstract-submission-main-grid-wrapper">
      <LoadingLayer isLoading={isLoading} action={loadingMessage} />
      <h1 className="snn-user-home-main-content-header snn-expand-grid-4">Abstract submission</h1>
      <div className="snn-expand-grid-4">
        <h2 className="snn-user-home-main-content-large-semi">Important:</h2>
        <ul>
          <li>
            The abstracts should not exceed 2 pages.{' '}
            <span className="important-highlight">
              Any abstract exceeding this limit will be disqualified.
            </span>
          </li>
          <li>
            To submit your abstract for SNN 2024,{' '}
            <span className="important-highlight">
              click the &apos;Submit Abstract&apos; button located at the bottom of the page
            </span>
            . After submission, you will receive a confirmation email. If you do not receive the
            confirmation email, please contact us at{' '}
            <a className="submit-link" href="mailto:nnsymposium@ens.cnyn.unam.mx">
              nnsymposium@ens.cnyn.unam.mx
            </a>
          </li>
        </ul>
      </div>
      <div className="snn-expand-grid-4 snn-panel-form-wrapper snn-abstract-submission-grid-3">
        <div>
          <FormGroup label="Title" value={title} setValue={(e) => setTitle(e.target.value)} />
        </div>
        <div className="snn-form-group">
          <label>Presentation type</label>
          <select
            id="presentation_type_id"
            name="presentation_type_id"
            onChange={(e) => setPresentationType(e.target.value)}
          >
            <option value="Oral presentation">Oral presentation</option>
            <option selected value="Poster">
              Poster
            </option>
          </select>
        </div>
        <div className="snn-form-group">
          <label>Topic</label>

          <select id="topic_id" name="topic_id" onChange={(e) => setTopic(e.target.value)}>
            {TopicsList.map((topic) => {
              return (
                <option value={topic.name} key={topic.id} id="topic_id" name="topic_id">
                  {topic.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="snn-panel-form-wrapper snn-expand-grid-2">
        <h2 className="snn-user-home-main-content-large-semi">Content</h2>
        <FormGroupTextArea label="Introduction" setValue={setBackground} state={background} />
        <FormGroupTextArea label="Methodology" setValue={setMethodology} state={methodology} />
        <FormGroupTextArea label="Results" setValue={setResults} state={results} />
        <FormGroupTextArea label="Conclusion" setValue={setConclusion} state={conclusion} />

        <FormGroup
          label="Acknowledgments (max 50 words)"
          value={acknowledgments}
          setValue={(e) => setAcknowledgments(e.target.value)}
        />
      </div>
      <div className="snn-abstract-submission-grid-vertical snn-expand-grid-2-2 overflow-y">
        <div className="snn-panel-form-wrapper snn-white-panel">
          <h2 className="snn-user-home-main-content-large-semi">Authors & affiliations</h2>
          {authors.map((author, index) => (
            <div className="snn-panel-form-wrapper snn-panel-author" key={index}>
              <AuthorForm
                moveUp={moveUp}
                moveDown={moveDown}
                index={index}
                setAuthors={setAuthors}
                authors={authors}
                removeAuthor={removeAuthor}
              />
            </div>
          ))}
          <div className="snn-expand-grid-2 snn-abstract-submission-add-affiliation-button-wrapper">
            <button className="snn-abstract-submission-add-author-button" onClick={addAuthor}>
              Add author <span className="material-symbols-outlined">add</span>
            </button>
          </div>
        </div>
      </div>
      <div className="snn-expand-grid-2 snn-panel-form-wrapper">
        <h2 className="snn-user-home-main-content-large-semi">References</h2>
        {references.map((reference, index) => (
          <div
            key={index}
            className="snn-abstract-submission-affiliation-wrapper snn-expand-grid-2"
          >
            <div className="flex1">
              <FormGroup
                label=""
                value={reference}
                setValue={(e) => {
                  let newReferences = [...references];
                  newReferences[index] = e.target.value;
                  setReferences(newReferences);
                }}
              />
            </div>
            <button
              className="snn-abstract-submission-remove-primary"
              onClick={() => removeReference(index)}
            >
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        ))}
        <div className="snn-expand-grid-2 snn-abstract-submission-add-affiliation-button-wrapper">
          <button className="snn-abstract-submission-add-affiliation-button" onClick={addReference}>
            Add reference<span className="material-symbols-outlined">add</span>
          </button>
        </div>
      </div>
      <div className="snn-expand-grid-2-2 snn-panel-form-wrapper">
        <h2 className="snn-user-home-main-content-large-semi">Add image</h2>
        <div className="snn-add-img-grid">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
          <button className="snn-add-img-button" onClick={triggerFileInput}>
            Upload Image
          </button>
          {image && (
            <div>
              <h2 className="snn-user-home-main-content-large-semi">Preview</h2>
              <img
                className="snn-abstract-img-preview"
                onMouseDown={(e) => handleClickImage(e)}
                src={image}
                alt="Abstract"
              />
            </div>
          )}
          <div className="snn-expand-grid-2">
            <FormGroup
              label="Image footer"
              value={imageFooter}
              setValue={(e) => setImageFooter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="snn-expand-grid-4">
        <h1 className="subtitle-panel-section">Abstract preview</h1>
        <div className="snn-abstract-page ">
          <h1 className="snn-abstract-page-title">{title}</h1>
          {authorsString.length > 20 ? (
            <>
              <p className="snn-abstract-page-authors">{parse(authorsString)}</p>
            </>
          ) : (
            <></>
          )}
          {affiliationsString.length > 13 ? (
            <>
              <p className="snn-abstract-page-affiliation">{parse(affiliationsString)}</p>
            </>
          ) : (
            <></>
          )}
          {background.length > 8 ? (
            <>
              <h2 className="snn-abstract-page-sub-title">Introduction</h2>
              <div
                className="snn-abstract-page-text"
                dangerouslySetInnerHTML={createMarkup(background)}
              ></div>
            </>
          ) : (
            <></>
          )}
          {methodology.length > 8 ? (
            <>
              <h2 className="snn-abstract-page-sub-title">Methodology</h2>
              <div
                className="snn-abstract-page-text"
                dangerouslySetInnerHTML={createMarkup(methodology)}
              ></div>
            </>
          ) : (
            <></>
          )}
          {results.length > 8 ? (
            <>
              <h2 className="snn-abstract-page-sub-title">Results</h2>
              <div
                className="snn-abstract-page-text"
                dangerouslySetInnerHTML={createMarkup(results)}
              ></div>
            </>
          ) : (
            <></>
          )}
          {image && (
            <img
              className="snn-abstract-img"
              onMouseDown={(e) => handleClickImage(e)}
              src={image}
              alt="Abstract"
            />
          )}
          {imageFooter.length > 0 ? (
            <>
              <p className="snn-abstract-page-image-footer">{imageFooter}</p>
            </>
          ) : (
            <></>
          )}
          {conclusion.length > 8 ? (
            <>
              <h2 className="snn-abstract-page-sub-title">Conclusion</h2>
              <div
                className="snn-abstract-page-text"
                dangerouslySetInnerHTML={createMarkup(conclusion)}
              ></div>
            </>
          ) : (
            <></>
          )}
          {referencesString.length > 4 ? (
            <>
              <h2 className="snn-abstract-page-sub-title">References</h2>
              <p className="snn-abstract-page-text">{parse(referencesString)}</p>
            </>
          ) : (
            <></>
          )}
          {acknowledgments ? (
            <>
              <h2 className="snn-abstract-page-sub-title">Acknowledgments</h2>
              <p className="snn-abstract-page-text">{acknowledgments}</p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div onClick={saveAbstract} className="snn-abstract-save-draft-button">
        <span className="material-symbols-outlined">save_as</span>
        <span className="snn-abstract-save-draft-button-label">Save draft & close</span>
      </div>
      <div className="snn-expand-grid-4 snn-panel-form-wrapper">
        <div className="snn-submit-abstract-buttons-wrapper flex-start">
          <button className="button" onClick={showModal}>
            Submit abstract
          </button>
        </div>
      </div>
      <div className={'snn-abstract-submission-terms-modal-wrapper ' + (modalShwon ? '' : 'hide')}>
        <div
          className={
            'snn-abstract-submission-terms-modal snn-panel-form-wrapper ' +
            (modalShwon ? '' : 'hide')
          }
        >
          <div className={modalShwon ? '' : 'hide'}>
            <h3 className="snn-user-home-main-content-large-semi">General Terms and Conditions</h3>
            <p>
              1. The Author(s) grant Symposium of Nanoscience and Nanomaterials 2024 (SNN 2024)
              permission to use his/her name, likeness and biography for the purpose of promoting
              the aforementioned Work, the presentation of the Work, or related SNN 2024 activities.
            </p>
            <p>
              2. I warrant and represent that I have properly cited the source of all supporting
              material included in the Presentation
            </p>
            <p className="snn-abstract-submission-condition">
              You must agree with these terms and conditions to send the abstracts
            </p>

            {'Oral presentation' === presentationType ? (
              <div>
                <h3 className="snn-user-home-main-content-large-semi">
                  Terms and Conditions Applicable to Oral Presentations
                </h3>
                <p>
                  The Author(s) hereby grant permission to photograph and record any presentation
                  (the “Presentation”) made by him/her (them) at the Symposium of Nanoscience and
                  Nanomaterials 2024 (SNN 2024), and to use, distribute, archive, copy and edit the
                  photograph(s) and recording and all supporting material accompanying the
                  Presentation, in whole or in part. The photograph(s) and recording shall belong to
                  SNN 2024, and the Author(s) shall have no right of approval, no claim in contract
                  or tort arising out of their use, and shall not receive any compensation for their
                  use now or in the future.
                </p>
                <p>
                  Please check the appropriate box below to indicate your permission with regard to
                  broadcasting:
                </p>

                <p>
                  <input
                    type="radio"
                    id="huey"
                    name="condition_selected"
                    value="I agree to my presentation being broadcast (in real time or on demand on the internet or other digital media) without limitation.
                (RECOMMENDED)"
                    checked
                    onChange={(e) => setCondition(e.target.value)}
                  />
                  I agree to my presentation being broadcast (in real time or on demand on the
                  internet or other digital media) without limitation. (RECOMMENDED)
                </p>

                <p>
                  <input
                    type="radio"
                    id="huey"
                    name="condition_selected"
                    value="I agree to my presentation being broadcast in real time, but DO NOT agree for it to be accessible
                for viewing by registrants on demand on the internet or other digital media. I ACKNOWLEDGE THE ORDER OF
                PRESENTATIONS MAY BE IMPACTED BY THIS DECISION."
                    onChange={(e) => setCondition(e.target.value)}
                  />
                  I agree to my presentation being broadcast in real time, but DO NOT agree for it
                  to be accessible for viewing by registrants on demand on the internet or other
                  digital media. I ACKNOWLEDGE THE ORDER OF PRESENTATIONS MAY BE IMPACTED BY THIS
                  DECISION.
                </p>

                <p>
                  <input
                    type="radio"
                    id="huey"
                    name="condition_selected"
                    value="Check here if you DO NOT grant SNN 2022 permission to record or broadcast your Presentation. I
                ACKNOWLEDGE THE ORDER OF PRESENTATIONS MAY BE IMPACTED BY THIS DECISION"
                    onChange={(e) => setCondition(e.target.value)}
                  />
                  Check here if you DO NOT grant SNN 2024 permission to record or broadcast your
                  Presentation. I ACKNOWLEDGE THE ORDER OF PRESENTATIONS MAY BE IMPACTED BY THIS
                  DECISION
                </p>
              </div>
            ) : null}
            <div className="snn-submit-abstract-buttons-wrapper">
              <button className="button" onClick={() => setModalShwon(false)}>
                Cancel
              </button>
              <button className="button" onClick={submitAbstract}>
                Agree with terms and conditions & send the abstracts
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
