import OliviaGraeve from '../assets/images/keynotes/olivia-graeve.jpeg';
import Hailin from '../assets/images/keynotes/Hailin_amp.png';
import Israel from '../assets/images/keynotes/israel_amp.png';
import Rodolfo from '../assets/images/keynotes/rodolfo.jpg';
import Eric from '../assets/images/keynotes/eric.jpg';
import Francisco from '../assets/images/keynotes/francisco.png';
import Octavio from '../assets/images/keynotes/octavio.jpeg';
import Minerva from '../assets/images/keynotes/minerva.png';

export const KeynoteList = [
  {
    id: 0,
    name: 'Dr. Israel Mejia',
    affiliation: 'Director QSM Semiconductores, México',
    img: Israel,
    topic_id: 4,
    resume:
      '"Dr. Jesús Israel Mejía Silva received his bachelor\'s degree with honors\n' +
      'from the Higher School of Electrical Engineering of the National\n' +
      "Polytechnic Institute in 2003. After receiving his Master's degree\n" +
      '(2006) and Doctorate in 2010 from the Department of Electrical\n' +
      'Engineering from CINVESTAV Zacatenco, he joined the University of\n' +
      'Texas at Dallas as a Researcher for the Department of Engineering\n' +
      'and Materials. As key personnel for the link between academia and\n' +
      "industry, Dr. Mejía's activities included the direction of research groups\n" +
      'for the development of innovative technologies in the area of design,\n' +
      'manufacturing and characterization of semiconductor devices. He has\n' +
      'worked in the development of technologies for technology-based\n' +
      'companies and for projects in the transnational semiconductor industry.\n' +
      'In 2016 the IEEE awarded him the distinction of “Senior Member” and\n' +
      'he is currently part of the National System of Mexican Researchers\n' +
      '(SNI). Dr. Mejía was the Director of the Electrical and Electronic\n' +
      'Engineering Division at CIDESI; He is currently the General Director\n' +
      'of QSM Semiconductors, in Querétaro, Mexico and the President of\n' +
      'the Mexican Microfabrication Network MicroFabMX."',
    title: 'A Look at the Current Status of the Technology and the Global Market',
    abstract:
      '<p>[Pre-COVID Era] The current lack of integrated circuits has been caused by factors such as the massive purchase of chips by cell phone companies, such as Apple and Samsung, to enter the 5G network during the era. pre-COVID. Additionally, there were trade sanctions imposed on phone giants like Huawei that resulted in massive chip purchases before the sanctions took effect. This was already keeping the semiconductor manufacturing industry extremely busy.</p>\n' +
      '\n' +
      '\n' +
      "<p>[Post-COVID Era] In addition to these pre-pandemic effects, the declaration of the COVID-19 Pandemic led the world's governments to take certain precautions to prevent massive infections in crowded environments that led to a temporary stoppage of giant companies in the industry. semiconductor manufacturing such as TSMC, Samsung, Intel, etc. Likewise, the confinement of people in their homes led to an increase in the consumption of electronics such as computers, smartphones, video game consoles and hardware purchased by software, video and computing companies that saw an exponential increase in their sales. such as Netflix, AWS, Amazon, Microsoft, among others.</p>",
  },
  {
    id: 1,
    name: 'Dr. Hailin Zhao Hu',
    affiliation: 'IER-UNAM, Mexico',
    img: Hailin,
    topic_id: 1,
    resume:
      'Nació en Shanghai, China, y mexicana por naturalización. Obtuvo la licenciatura en Física, maestría en Ciencias (Física) y el doctorado en Ciencias (Ciencia de Materiales), todos en la Universidad Nacional Autónoma de México (UNAM). Es Investigador Titular “C” de tiempo completo del Instituto de Energías Renovables de la UNAM. Tiene el nivel 3 del Sistema Nacional de Investigadoras e Investigadores.\n' +
      '\n' +
      'En los últimos años su principal interés está en la investigación de materiales semiconductores orgánicos e inorgánicos para su aplicación en celdas solares preparadas por métodos de soluciones, especialmente celdas solares de perovskita. También ha trabajado en temas relacionados con ventanas inteligentes, sensores químicos, así como propiedades eléctricas, ópticas y mecánicas de materiales compuestos. \n',
    title:
      'Impact of dipole moment values of solution additives on air-processed\n' +
      'perovskite thin films.',
    abstract:
      '<p>\n' +
      'Additives have been widely used in perovskite precursor solutions to remove the moisture and improve the quality of perovskite thin films. In this talk three ionic surfactants, \n' +
      'didodecyldimethylammonium bromide (DDABr), sodium lauryl ether sulfate (NaLES) and sodium lauryl sulfate (NaLS), with different dipole moment values: 0.907, 17 and 212 Debye, respectively, \n' +
      'have been used as anti-solvent additives in spin-coating processes for perovskite thin film deposition. These additives impact in different ways on the crystallinity, wettability and morphology of \n' +
      'perovskite thin films, as well as on the stability and efficiency of air-processed perovskite solar cells (PSCs). Among the three, NaLES (of the highest dipole moment) is \n' +
      'the most efficient to extract moisture from the perovskite precursor coatings, giving the average power conversion efficiency (PCE) of 17.42% and the best PCE of 18.75% of the corresponding PSCs. \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'Furthermore, NaLES has been used as an additive in the perovskite precursor solution to prepare large area perovskite thin films by Blading process, giving a similar improvement in the quality of perovskite \n' +
      'thin films. It is concluded that ionic surfactants of high dipole moments are good candidates as solution additives to improve the efficiency and stability of air-processed PSCs.\n' +
      '</p>',
  },
  {
    id: 2,
    name: 'Dr. Rodolfo Zanella Specia',
    affiliation: 'ICAT-UNAM, Mexico',
    img: Rodolfo,
    topic_id: 4,
    resume:
      '"Rodolfo Zanella is a senior researcher at the Institute for Applied Sciences and Technology (ICAT) at the National Autonomous University of Mexico (UNAM). He earned his Ph.D. in process engineering and catalysis from the Université Pierre et Marie Curie-Paris VI (now Sorbonne Université), France, in 2003. He served as Academic Deputy and later as the Director of the Center for Applied Sciences and Technological Development from 2013 to 2018. He then assumed the role of Director of ICAT from 2018 to 2022 and subsequently served as Director of the Representation Office of UNAM in France (2022-2023). Currently, he holds the position of Coordinator of the Catalysis and Surface Processes Group and the University Laboratory of Environmental Nanotechnology at ICAT. His research interests encompass the deposition of monometallic and bimetallic nanoparticles on oxides, the\n' +
      'synthesis of pure and doped metal oxides, catalysis focusing on exhaust gas reactions\n' +
      '(CO oxidation, NO reduction, total oxidation), photocatalytic degradation of organic\n' +
      'compounds, and the photocatalytic production of hydrogen through water splitting."',
    title:
      'Strategies to increase the photoactivity of semiconductors in the hydrogen evolution reaction',
    abstract:
      '<p>\n' +
      'The photocatalytic hydrogen evolution reaction (HER) is a promising alternative for sustainable energy since it involves the absorption of light to produce hydrogen by irradiating oxide semiconductors. \n' +
      'The energy states and surface plasmon resonance of composite materials significantly influence photocatalytic processes and energy generation by enhancing the separation, transport, and mobility of charge carrier. \n' +
      'One of the keys to increase photocatalytic efficiency is to avoid electron-hole pair recombination, for this propose, we have explored different alternatives: i) surface modification of the semiconductor with metal or \n' +
      'metal oxide nanoparticles, ii) formation of heterojunctions between two semiconductors, iii) the use of hole scavengers (sacrificial agents). Incorporating metals or metal oxide nanoparticles as co-catalysts on semiconductor \n' +
      'surfaces has proven to be an effective strategy in amplifying photoactivity for the HER, primarily by creating energy states that modulate surface plasmon excitation and interfacial charge transport. \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'In this talk, a study \n' +
      'about the photoactivity in the HER using Au–M x O y and Au-M nanoparticles on TiO 2 –P25 prepared by the deposition-precipitation method will be presented. The results will be compared to the hydrogen production of \n' +
      'the corresponding Au and M x O y nanoparticles on TiO 2 to evaluate the effect of combining Au with Ag 2 O, Cu 2 O, NiO, CoO x or Pd as co-catalysts. Likewise, the Rh-Cu/TiO 2 was used as a highly efficient photocatalyst, \n' +
      'exhibiting a remarkably high photocatalytic hydrogen evolution performance, which was about twofold higher than the one of the Cu/TiO 2 monometallic photocatalyst. This outstanding performance was due to the efficient\n' +
      'charge carrier transfer as well as to the delayed electron-hole recombination rate, caused by the addition of Rh. Hydrogen evolution was studied using methanol, ethanol, 2-propanol and butanol as scavengers.\n' +
      'The methanol-water system, which showed the highest hydrogen production, was studied under 254, 365-and 450 nm irradiation; Rh-Cu/TiO 2 showed high photocatalytic activity and it was active under visible light\n' +
      'irritation due to its strong light absorption in the visible region, low band gap value and ability to reduce the electron and hole recombination. The influence of the different parameters of the photocatalyst\n' +
      'synthesis and reaction conditions on the photocatalytic activity was investigated in detail. Examples of the formation of heterojunctions between two semiconductors, as well as results of characterization of the\n' +
      'materials by UV–vis diffuse reflectance, TEM, ICP, EDS and XPS will be shown. \n' +
      '</p>',
  },
  {
    id: 3,
    name: 'Prof. Eric Potma',
    affiliation: 'Department of Chemistry, UCI, USA',
    img: Eric,
    topic_id: 3,
    resume:
      'Eric Potma got his Masters at the University of Groningen in 1996, where he also received his Ph.D. degree in 2001. While working in the ultrafast spectroscopy group of Prof. Douwe Wiersma, Eric focused his research on the development of laser sources for microscopy and the application of nonlinear methods to optical imaging. In 2001, Potma joined the group of Prof. Sunney Xie at Harvard University as a postdoctoral fellow. During this time, he was been involved with projects on synchronizing mode-locked lasers, visualizing lipid bilayers with coherent Raman scattering microscopy and vibrational imaging of tissue in vivo at video rate. In 2005, Eric joined the Department of Chemistry at the University of California in Irvine, where he currently is a Professor of Chemistry. His group focuses on the characterization of nano-structured materials and biological tissues with the aid of new nonlinear optical imaging techniques. ',
    title: 'Improving light absorption in silicon by changing the momentum properties of light',
    abstract:
      '<p>\n' +
      'Silicon is a ubiquitous material in electronics, yet its implementation in opto-electronic devices is significantly less prominent due to its underwhelming optical properties compared to\n' +
      'other semiconducting materials. Silicon’s low absorption coefficient also has implications for solar- energy conversion, as the thick solar cells needed to achieve sufficient light absorption render\n' +
      'Si-based solar panels affect its competitiveness compared to conventional fossil fuels. Increasing the absorptive properties of silicon itself would reduce material costs and significantly improve the\n' +
      'economic viability of Si-based solar cells. In this presentation, we discuss an approach that improves the light absorption in silicon, not by changing the material itself, but by changing the properties of\n' +
      'the incident light instead. \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'We achieve this by confining the photon to scales below ~3 nm, which, through the uncertainty principle, is associated with a broadening of the photonic momentum spectrum, comparable to\n' +
      'electron momenta in solid state materials. Equipped with such momentum, the photon is now capable of inducing direct transitions in silicon, without the involvement of a phonon. This approach effectively converts\n' +
      'silicon from an indirect to a direct semiconductor, thereby dramatically improving its light absorption properties by more than three orders of magnitude. This strategy may impact silicon photonics, and solar\n' +
      'energy conversion in particular, as it breaks with the notion that silicon is a poor optical material. \n' +
      '</p>',
  },
  {
    id: 4,
    name: 'Dr. Olivia Graeve',
    affiliation: 'UC San Diego Jacobs School of Engineering',
    img: OliviaGraeve,
    topic_id: 1,
    resume:
      'Prof. Olivia is a Professor of Mechanics and Materials at the University of California, San Diego, in the Department of Mechanical and Aerospace Engineering. She received her B.S. in Structural Engineering from the University of California, San Diego in 1995 and her Ph.D. in Materials Science and Engineering from the University of California, Davis in 2001. She is currently the Director of the CaliBaja Center for Resilient Materials and Systems and the IDEA Engineering Student Center at UC San Diego. Prof. Graeve has contributed to the development of human resources both as a research advisor and as an instructor, teaching courses in the general areas of structure and bonding, as well as the mechanical behavior of materials. She is an editor for Materials Letters and Scientific Reports. She has received numerous awards, including the National Science Foundation CAREER award in 2007 and the American Ceramic Society’s 2010 Karl Schwartzwalder Professional Achievement in Ceramic Engineering Award. She has also been involved in many activities related to the recruitment and retention of women and Hispanic students in science and engineering.',
    title: 'Synthesis, Crystallography and Properties of Materials for Extreme Environments',
    abstract:
      '<p>\n' +
      'The idea of living on Mars or the Moon has been a staple of science fiction since the 19th century. However, if this sci-fi dream were to ever become reality, \n' +
      'what would it be like to live there? Conditions make living on Mars extremely challenging. In particular, materials needed for such extreme environments need to be discovered and designed. \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'In this talk, we will present an overview and current research on ceramic materials, especially their crystallography, for potential uses at extreme environments, including ultra-high and ultra- low \n' +
      'temperatures, impact, and radiation. These research efforts build on significant international collaborations of the CaliBaja Center for Resilient Materials and Systems, a bi- national effort between \n' +
      'UC San Diego and the Universidad Nacional Autónoma de México. A short discussion on student exchange opportunities, such as the ENLACE summer research program, will also be described.\n' +
      '</p>',
  },
  {
    id: 5,
    name: 'Prof. Francisco Zaera',
    affiliation: 'UC Riverside, USA',
    img: Francisco,
    topic_id: 4,
    resume:
      'Research Director Francisco Zaera. With an illustrious career dedicated to understanding surface reactions at the molecular level, Dr. Zaera is set to enlighten us on the intricacies of catalysis and materials science.\n' +
      '\n' +
      "Dr. Francisco Zaera's expertise, coupled with an array of cutting-edge techniques, promises to unravel the mysteries of surface reactions. From catalysis to materials science, his research has far-reaching implications that shape our understanding of molecular interactions.\n",
    title: 'New Nanostructures for Increased Selectivity and Stability in Catalysis',
    abstract:
      '<p>\n' +
      'One of the major challenges in heterogeneous catalysis is the preparation of highly selective and robust catalysts. The goal is to be able to synthesize solids with stable surfaces containing a \n' +
      'large number of specific surface sites designed for the promotion of a particular reaction. New synergies between surface-science studies and novel nanosynthesis methodology promise to afford \n' +
      'new ways to design such highly selective catalysts in a controlled way. In this presentation we will provide a progress report on a couple of projects ongoing in our laboratory based on this approach.\n' +
      'Platinum- based catalysts have been prepared for the selective trans-to-cis conversion of olefins, with a design based on early surface-science work with model surfaces and quantum mechanical calculations \n' +
      'that indicated a particular preference for (111) facets in promoting the formation of the cis isomers. \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'This research is now being extended by using the concept of &quot;single-site catalysis&quot; with Pt-Cu \n' +
      'bimetallics for the selective hydrogenation of unsaturated aldehydes. In a second example, new metal@TiO 2 yolk-shell nanomaterials have been conceived to promote CO oxidation at cryogenic temperatures and to \n' +
      'suggest that in photocatalysis the role of the metal may not be to scavenge the excited electrons produced in the semiconductor upon absorption of light, as commonly believed, but rather to promote the \n' +
      'recombination of the adsorbed atomic hydrogen initially produced by reduction of H + on the surface of that semiconductor. New mixed-oxide surfaces are being designed using atomic layer deposition (ALD) as well. \n' +
      'Additional examples include the use of &quot;click&quot; chemistry to tether molecular functionality on porous solid materials and design tandem catalysts. \n' +
      '</p>',
  },
  {
    id: 6,
    name: 'Dr. Tonatiuh Ramírez Reivich',
    affiliation: 'IBT-UNAM, Mexico',
    img: Octavio,
    topic_id: 2,
    resume:
      'Dr.  Tonatiuh is a keynote speaker and a researcher in the field of bioprocessing and biotechnological applications. He is affiliated with the Universidad Nacional Autónoma de México (UNAM), where he conducts research on bioprocesses with biotechnological applications, and engineering and technology of fermentations and cell cultivation. His current research focus is on characterizing population heterogeneity for selecting Chinese hamster ovary cells with enhanced mitochondrial activity and biosynthetic capacity, which has applications in medicinal biotechnological drugs. He has received funding from the DIRECCIÓN GENERAL DE ASUNTOS DEL PERSONAL ACADÉMICO/UNIVERSIDAD NACIONAL AUTÓNOMA DE MÉXICO for his project. He has also attained PRIDE Level D and SNII Level III as recognitions for his research in fermentation engineering and cell cultivation technologies.',
    title:
      'Production of novel nanobiomaterials and devices generated from the assembly of viral proteins: From Bioprocess Monitoring to Bioprocess Integration',
    abstract:
      '<p>\n' +
      'The self-assembly capacity of various biological macromolecules can be exploited for the generation of novel materials of nanometric dimensions.  \n' +
      'The interest in such nanomaterials is rapidly increasing due to their enormous potential in many diverse areas of applications, including medicine and electronics among others.  \n' +
      'In particular, virus-like particles (VLP) and other multimeric protein entities present interesting structural properties that make them ideal candidates for fabricating nanomaterials. \n' +
      'Rotaviral proteins are especially suitable for such applications due to their capacity for assembling into either icosaedric or tubular structures.  In this lecture we will present the use of\n' +
      'the insect-cell baculovirus expression vector system for producing either VLP or synthetic viral structures (SVS) from rotavirus and their further functionalization for the manufacture of metallic nanotubes. \n' +
      'Rational infection strategies and monitoring approaches were developed to optimally produce either tubular SVS (formed by VP6), single-layered (formed by VP2), double-layered (formed by VP2 and VP6), \n' +
      'or tripled-layered VLP (formed by VP2, VP6, and VP7).  \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'Novel and scalable purification schemes were developed, including the use of two-phase aqueous systems and gel permeation chromatography, for the \n' +
      'purification of the various rotaviral VLP or SVS.  Such schemes were shown to be particularly suited for separating large macrostructures, in particular VLP from baculovirus, without the need of cumbersome \n' +
      'zonal ultracentrifugation methods.  Fluorescence anisotropy and static light scattering measurements were employed to quantitatively characterize the in vitro assembly and disassembly processes of VLP and SVS.  \n' +
      'This allowed a detail understanding of the formation mechanisms of icosaedric or tubular structures.  All these studies were integrated in a complete process for the manufacture of tubular SVS, of 45 or 70 nm in \n' +
      'diameter and up to several microns in length, that served as scaffold for the deposition of several metals, including gold, silver, palladium, and platinum.  The formation of these novel nanobiomaterials was confirmed \n' +
      'by dynamic light scattering, typical plasmons of the reduced metals as observed in their absorbance spectra, and transmission electron microscopy.  \n' +
      '</p>\n' +
      '\n' +
      '<p>\n' +
      'In conclusion, we developed a new versatile platform to synthesize \n' +
      'different nanobiomaterials with unique structural, mechanical, magnetic, and electrical characteristic that can present important potential applications as nanodevices with peculiar electronic, optic or magnetic \n' +
      'properties. As a final example of the platform established, we will present the development of a microchip based on VP6 nanotubes functionalized with gold and employed as responsive implants in murine experimental \n' +
      'models for Parkinson´s disease. \n' +
      '</p>',
  },
  {
    id: 7,
    name: 'Dr. Minerva González Melchor',
    affiliation: 'IFUAP-BUAP, Mexico',
    img: Minerva,
    topic_id: 1,
    resume:
      'Dr Minerva González Melchor obtained her master degree in physics and later, in 2002 a PhD in Physics, both at the Center for Research and Advanced Studies of the National Polytechnic Institute in Mexico City. She was a postdoc in the Department of Chemistry at Imperial College London in the United Kingdom. She is currently a member of the Institute of Physics &quot;Luis Rivera Terrazas&quot; of the Meritorious Autonomous University of Puebla, working in the area of &quot;Molecular simulation of Liquids&quot;. She is a member of the National System of Researchers and is recognized as a professor with profile PRODEP-SEP for teaching.',
    title: 'The dynamics of molecules and nanostructures in liquids',
    abstract:
      '<p>\n' +
      'Liquids can be simple or complex depending on the particles forming them. They are everyday systems whose behavior and properties can be predicted by computer simulation techniques.\n' +
      ' For liquids formed of low molecular weight molecules, analysis of extreme conditions, interactions\n' +
      'between different types of molecules, phase behavior, average structure and dynamic properties can be determined at a reasonable computational cost.\n' +
      ' More challenging is the study of nanostructures, proteins and other particles in liquid media when the description at the level of atoms is relevant. \n' +
      ' </p>\n' +
      ' <p>\n' +
      ' These studies are of interest in many areas of science and technology. Nanostructures can be used for drug delivery and water remediation, for instance.\n' +
      '  Some simulation studies of three distinct systems are summarized: (1) the properties of water; (2) the behavior of a nanotube and its interactions with herbicide in aqueous medium; \n' +
      '  and (3) a fullerene/water mixture studied at conditions of liquid-vapor coexistence. In each case, molecular dynamics simulations are conducted to calculate the properties of interest. \n' +
      '  These studies reveal microscopic aspects, and provide useful insights on properties relevant to applications.\n' +
      '  </p>',
  },
];
