import { Logo } from './Logo';
import { OptionsWrapper } from './OptionsWrapper';
import './top-bar.css';
import MenuIcon from './menu_black_24dp.svg';
import { useState } from 'react';
import PropTypes from 'prop-types';

export const TopBar = ({ options }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <nav className="snn-top-menu-wrapper">
      <Logo />
      <div className="menu-button-wrapper" onClick={toggleMenu}>
        <img src={MenuIcon} alt="Menu icon" />
      </div>
      <div className={isMenuOpen ? null : 'hide-cellphone'}>
        <OptionsWrapper options={options} />
      </div>
    </nav>
  );
};

TopBar.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
};
