import { Link } from 'react-router-dom';
import { TopicsList } from '../../../data/TopicsList';

export const Topics = () => {
  return (
    <>
      <section className="snn-page-section snn-topics">
        <h2 className="snn-title">Topics</h2>
        <div className="snn-topic-section">
          {TopicsList.map((t) => (
            <Link key={t.id} to={'/topics/' + t.id}>
              <div>
                <img src={t.src} alt="Topic logo" />
              </div>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};
