import { SideMenu } from '../user-home/SideMenu';
import { LoadingLayer } from '../../LoadingLayer';
// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const fetchUserREceipt = async (setReceipt) => {
  try {
    const response = await fetch(
      'https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/scholarships',
      {
        headers: {
          'user-token': localStorage.getItem('id_token'),
        },
      },
    );
    const data = await response.json();
    console.log(data.Items);
    setReceipt(data.Items);
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const Scholarships = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [studiesProof, setStudiesProof] = useState(null);
  const [UserStudiesProof, setUserStudiesProof] = useState([]);
  const [haveFoodAllergies, setHaveFoodAllergies] = useState(false);
  const [foodAllergies, setFoodAllergies] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState('');
  const [food, setFood] = useState('Chicken Cordon Bleu/Pollo Cordon Bleu');
  const [career, setCareer] = useState('');
  const [adscription, setAdscription] = useState('');
  const [abstractTitle, setAbstractTitle] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [abstractCode, setAbstractCode] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage('Loading...');
    fetchUserREceipt(setUserStudiesProof).then(() => {
      setIsLoading(false);
    });
  }, []);

  const foodAllergiesCheckboxChange = (event) => {
    setHaveFoodAllergies(event.target.checked);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const submitReceipt = async () => {
    setIsLoading(true);
    setLoadingMessage('Submitting...');
    const base64StudiesProof = studiesProof
      ? (await fileToBase64(studiesProof)).split(',')[1]
      : null;
    const data = {
      studiesProof: base64StudiesProof,
      food,
      haveFoodAllergies,
      foodAllergies,
      name,
      career,
      country,
      city,
      adscription,
      abstractTitle,
      abstractCode,
    };
    fetch('https://nt6dwl1xpc.execute-api.us-west-1.amazonaws.com/produccion/scholarships', {
      method: 'POST',
      headers: {
        'user-token': localStorage.getItem('id_token'),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          navigate('/home');
        }
      })
      .then((data) => {
        console.log(data);
      });
  };

  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <div className="snn-abstract-submission-main-grid-wrapper">
        <LoadingLayer isLoading={isLoading} action={loadingMessage} />
        <h1 className="snn-user-home-main-content-header snn-expand-grid-4">
          Scholarship submission
        </h1>
        {UserStudiesProof.length > 0 ? (
          <>
            <div className="snn-expand-grid-4">
              <h2 className="snn-user-home-main-content-large-semi">
                Scholarship request submitted
              </h2>
            </div>
          </>
        ) : (
          <>
            <div className="snn-expand-grid-4">
              <h2 className="snn-user-home-main-content-large-semi">
                STUDENT SCHOLARSHIPS FOR UNDERGRADUATE AND POSTGRADUATE STUDENTS
              </h2>
              <p>
                Attention students: All undergraduate, master&apos;s, and doctoral students whose
                abstracts have been accepted and are presenting them will be entitled to a
                scholarship. On Monday the 8th, the scholarship system will be available on our
                website. Remember, you will need current proof of your student status.
              </p>
              <p>
                <strong>All files must be on pdf format, and have a size lower than 3 MB</strong>
              </p>
            </div>
            <div className="snn-panel-form-wrapper snn-expand-grid-2">
              <h2 className="snn-user-home-main-content-large-semi">Student information </h2>
              <div className="snn-form-group">
                <label>
                  Student proof status (Photocopy of your dated student ID (with current date)) /
                  Comprobante de estudios (Copia de la credencial de estudiante con fecha reciente)
                </label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => setStudiesProof(e.target.files[0])}
                />
              </div>
              <div className="snn-form-group">
                <label>Name for receipt / Nombre para recibo</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
              </div>
              <div className="snn-form-group">
                <label>Career / Carrera</label>
                <input value={career} onChange={(e) => setCareer(e.target.value)} type="text" />
              </div>
              <div className="snn-form-group">
                <label>Country / País</label>
                <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" />
              </div>
              <div className="snn-form-group">
                <label>City / Ciudad</label>
                <input value={city} onChange={(e) => setCity(e.target.value)} type="text" />
              </div>
              <div className="snn-form-group">
                <label>Adscription / Adscripción</label>
                <input
                  value={adscription}
                  onChange={(e) => setAdscription(e.target.value)}
                  type="text"
                />
              </div>
              <div className="snn-form-group">
                <label>Abstract title / Título del resumen</label>
                <input
                  value={abstractTitle}
                  onChange={(e) => setAbstractTitle(e.target.value)}
                  type="text"
                />
              </div>
              <div className="snn-form-group">
                <label>Abstract code / Código del resumen</label>
                <input
                  value={abstractCode}
                  onChange={(e) => setAbstractCode(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="snn-panel-form-wrapper snn-expand-grid-2-2">
              <h2 className="snn-user-home-main-content-large-semi">Food preferences</h2>
              <div className="snn-form-group">
                <select
                  id="degree"
                  name="degree"
                  value={food}
                  onChange={(e) => setFood(e.target.value)}
                >
                  <option selected value="Chicken Cordon Bleu/Pollo Cordon Bleu">
                    Chicken Cordon Bleu/Pollo Cordon Bleu
                  </option>
                  <option name="degree" value="Shrimp with fillet/Camaron con Filete">
                    Shrimp with fillet/Camaron con Filete
                  </option>
                  <option name="degree" value="Vegetarian/Vegetariano">
                    Vegetarian/Vegetariano
                  </option>
                </select>
              </div>
              <div className="snn-form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={haveFoodAllergies}
                    onChange={foodAllergiesCheckboxChange}
                  />
                  Food allergies? / ¿Alergias alimentarias?
                </label>
              </div>
              {haveFoodAllergies && (
                <div className="snn-form-group">
                  <label>
                    Please specify which allergies you have in the space provided below / Por favor
                    especifica a qué eres alergico
                  </label>
                  <input
                    value={foodAllergies}
                    onChange={(e) => setFoodAllergies(e.target.value)}
                    type="text"
                  />
                </div>
              )}
            </div>
            <div className="snn-expand-grid-4">
              <div className="snn-submit-abstract-buttons-wrapper flex-start">
                <button onClick={submitReceipt} className="button">
                  Submit receipt
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
