import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { Footer } from '../../footer/Footer';
import { SpeakersList } from '../../../data/speakersList';
import { Speaker } from './Speaker';
import { KeynoteList } from '../../../data/KeynotesList';
import Alberto from '../../../assets/images/speakers/AlbertoGuijosaC.jpg';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { TopicsList } from '../../../data/TopicsList';

export const SpeakerModal = ({ speaker, closeModal }) => {
  return (
    <>
      {speaker ? (
        <div className="snn-speaker-modal">
          <div className="snn-speaker-modal-top-bar">
            <span onClick={closeModal} className="snn-user-menu-icon material-symbols-outlined">
              close
            </span>
          </div>
          <div className="snn-speaker-modal-content">
            <div className="snn-speaker-modal-content-img-wrapper">
              <img src={speaker.img} alt={speaker.alt} />
            </div>
            <div className="snn-speaker-modal-content-main">
              <h2>{speaker.name}</h2>
              <p className="snn-speaker-modal-affiliation">{speaker.affiliation}</p>
              <p className="snn-speaker-resume">{speaker.resume}</p>
              {speaker.abstract ? (
                <>
                  <hr />
                  <h2>{speaker.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: speaker.abstract }} />
                </>
              ) : null}
            </div>
            {speaker.topic_id ? (
              <div className="snn-speaker-modal-content-bottom">
                <h3>Topic</h3>
                <div>
                  <p>{TopicsList.filter((t) => t.id === speaker.topic_id)[0].name}</p>
                  <img src={TopicsList.filter((t) => t.id === speaker.topic_id)[0].src_shield} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const SpeakersWrapper = () => {
  const [selectedSpeaker, setSelectedSpeaker] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const specialGuestList = [
    {
      id: 0,
      name: 'Prof. Alberto Güijosa',
      affiliation: 'ICN-UNAM, Mexico',
      img: Alberto,
      topic_id: null,
      resume:
        'Alberto Güijosa is a Professor at the Institute of Nuclear Sciences of the National Autonomous University of Mexico, where he is additionally the Director of the Graduate Program in Physical Sciences. He got his Ph.D. in 1999 at Princeton University, specializing in string theory. For his work in the holographic (or AdS/CFT) correspondence, he was awarded the 2010 Research Prize of the Mexican Academy of Sciences.',
      title: "Some Essentially New Physical Ideas: Feynman's Legacy",
      abstract:
        '<p>Richard P. Feynman is widely recognized as one of the most original and influential theoretical physicists of the twentieth century. This talk will provide a brief overview of some of his contributions, with emphasis on his Nobel-Prize-winning work, at a level accessible to non-specialists.</p>',
    },
  ];

  const selectSpeaker = (speakerId, list) => {
    console.log(speakerId, list);
    const speaker = list.find((speaker) => speaker.id === speakerId);
    setSelectedSpeaker(speaker);
    setIsModalOpen(true);
  };

  return (
    <>
      <TopBar options={PublicOptions} />
      <div className={isModalOpen ? '' : 'hide'}>
        <SpeakerModal speaker={selectedSpeaker} closeModal={closeModal} />
      </div>
      <div className="content">
        <section className="snn-page-section">
          <h2 className="snn-title">Special guest</h2>
          <div
            onClick={() => selectSpeaker(0, specialGuestList)}
            className="snn-speakers-cards-wrapper"
          >
            <div className="snn-speaker-card">
              <div className="snn-speakers-card-img">
                <img src={Alberto} alt="Prof. Alberto Güijosa" />
              </div>
              <div className="snn-speakers-card-description special-guest">
                <h2>Prof. Alberto Güijosa</h2>
                <p>ICN-UNAM, Mexico</p>
              </div>
            </div>
          </div>
          <h2 className="snn-title">Plenaries Speakers</h2>
          <div className="snn-speakers-cards-wrapper">
            {SpeakersList.map((speaker) => (
              <Speaker
                speaker={speaker}
                key={speaker.id}
                handleClick={() => selectSpeaker(speaker.id, SpeakersList)}
              />
            ))}
          </div>
          <h2 className="snn-title">Keynotes Speakers</h2>
          <div className="snn-keynotes-cards-wrapper">
            {KeynoteList.map((speaker) => (
              <Speaker
                speaker={speaker}
                key={speaker.id}
                handleClick={() => selectSpeaker(speaker.id, KeynoteList)}
              />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

SpeakerModal.propTypes = {
  speaker: PropTypes.any.isRequired,
  closeModal: PropTypes.func,
};
