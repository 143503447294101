import './About.css';
import IMG_0776 from '../../../assets/history_photos/IMG_0776.JPG';
import IMG_0576 from '../../../assets/history_photos/IMG_0576.JPG';
import IMG_0583 from '../../../assets/history_photos/IMG_0583.JPG';
import IMG_0578 from '../../../assets/history_photos/IMG_0578.JPG';
import IMG_0581 from '../../../assets/history_photos/IMG_0581.JPG';

export const About = () => {
  return (
    <>
      <section className="snn-page-section">
        <div className="snn-about">
          <section id="snn-committee">
            <h2 className="snn-title">Organizing Committee</h2>
            <div className="snn-committee-list-container">
              <ul>
                <li>Sergio Fuentes Moyado, chair</li>
                <li>Elena Smolentseva</li>
                <li>Noemí Abundiz Cisneros</li>
                <li>Catalina López Bastidas</li>
                <li>Mario H. Farias Sanchez</li>
                <li>Ruben D. Cadena Nava</li>
                <li>Ernesto Cota</li>
                <li>Leonardo Morales</li>
                <li>Perla Jazmín Sánchez López</li>
                <li>Karina Portillo</li>
                <li>Uriel Caudillo Flores</li>
                <li>Raúl Rangel Rojo</li>
                <li>Estrella Terán Hinojosa</li>
                <li>Carlos Belman Rodriguez</li>
                <li>Carolina Bohorquez Martinez</li>
                <li>Hugo Alejandro Borbon Nuñez</li>
                <li>H’Linh Hmok</li>
                <li>Javier Alonso López Medina</li>
                <li>Mauricio A. Trujillo Roldán</li>
              </ul>
            </div>
          </section>
          <section id="history">
            <h2 className="snn-title">History</h2>
            <p className="snn-justify">
              Since 1995 the Center of Nanoscience and Nanotechnology (CNyN), UNAM, Campus Ensenada,
              has carried out uninterruptedly this event whose main goal is presenting the latest
              results on Materials Science from research performed in prestigious institutions of
              Mexico and abroad. The first sixteen editions, the Symposium was named “Simposio en
              Ciencia de Materiales”; years later, between 2012 and 2014, the Symposium name was
              changed according to its new international scope and a change name of our institution:
              Centro de Nanociencias y Nanotecnologia (CNyN). In these years this event was named
              International Symposium on Nanoscience and Nanomaterials. In 2015 the event went back
              to a national scope and its name switched to Simposio de Nanociencias y
              Nanomateriales. From 2018 the Symposium of Nanoscience and Nanomaterials (IV SNN) has
              been jointly organized by CNyN and CICESE (Centro de Investigación Científica y
              Estudios Superiores de Ensenada). To date, there has been 25 editions of this academic
              event presenting the opportunity to stimulate interactions, to exchange experiences
              and to strengthen or initiate new collaborations.
            </p>
            <div className="snn-photo-section">
              <div>
                <img src={IMG_0776} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0576} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0583} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0578} alt="SNN 2019" />
              </div>
              <div>
                <img src={IMG_0581} alt="SNN 2019" />
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="snn-page-section">
        <h2 className="snn-title">Scientific Committee</h2>
        <div className="snn-scientific-committee">
          <section>
            <ul>
              <li>Ernesto Cota Araiza, CNyN-UNAM</li>
              <li>Ana Cecilia Noguez Garrido, IF-UNAM</li>
              <li>Priscilla Elizabeth Iglesias Vázquez, FC UABC</li>
              <li>Catalina López Bastida, CNyN-UNAM</li>
              <li>Leonardo Morales de la Garza, CNyN-UNAM</li>
              <li>Noemi Abundiz Cisneros, CNyN-UNAM</li>
              <li>Javier Alonso López Medina, CNyN-UNAM</li>
              <li>H’Linh Hmŏk, CNyN-UNAM</li>
              <li>Rodrigo Ponce Pérez, CNyN-UNAM</li>
              <li>Sandra Elizabeth Rodil Posada, IIM-UNAM</li>
              <li>Roberto Machorro Mejía, CNyN-UNAM</li>
              <li>Estrella Terán Hinojosa, CNyN-UNAM</li>
              <li>Oscar Raymond Herrera, CNyN-UNAM</li>
              <li>Jesus M Siqueiros Beltrones, CNyN-UNAM</li>
              <li>Subhash Sharma, CNyN-UNAM</li>
            </ul>
          </section>
          <ul>
            <li>Raúl Rangel Rojo, CICESE</li>
            <li>Eugenio Méndez Méndez, CICESE</li>
            <li>Julián Israel Aguilar Duque, FIAD UABC</li>
            <li>Uriel Caudillo Flores, CNyN-UNAM</li>
            <li>Mario Humberto Farias, CNyN-UNAM</li>
            <li>Juan Cruz Reyes, FQ-UABC</li>
            <li>Elena Smolentseva, CNyN-UNAM</li>
            <li>Mercedes Teresita Oropeza Guzmán, ITN</li>
            <li>Gabriela Alicia Díaz Guerrero, IF-UNAM</li>
            <li>Rodolfo Zanella Specia, ICAT-UNAM</li>
            <li>Sergio Fuentes Moyado, CNyN-UNAM</li>
            <li>David Alejandro Dominguez Vargas, CNyN-UNAM</li>
            <li>Karina Portillo Cortez, CNyN-UNAM</li>
            <li>Daniel Barron Pastor, CNyN-UNAM</li>
            <li>Guillermo Amaya Parra, FIAD UABC</li>
          </ul>
          <section>
            <ul>
              <li>Ruben D. Cadena Nava, CNyN-UNAM</li>
              <li>Norma Adriana Valdez Cruz, IIB-UNAM</li>
              <li>Mauricio Alberto Trujillo Roldán, IIB-UNAM</li>
              <li>Patricia Juárez Camacho, CICESE</li>
              <li>Pierrick Gerard Jean Fournier, CICESE</li>
              <li>Andrés Zárate Romero, CNyN-UNAM</li>
              <li>Oscar González Davis, CNyN-UNAM</li>
              <li>Alejandro Huerta Saquero, CNyN-UNAM</li>
              <li>Diana Garibo Ruíz, CNyN-UNAM</li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};
