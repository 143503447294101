import { TopBar } from '../../top-bar/TopBar';
import { Footer } from '../../footer/Footer';
import { PublicOptions } from '../../../data/publicOptions';
import { About } from '../about/About';
import { Banner } from './Banner';
import { Topics } from './Topics';
import './Home.css';
import { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sponsors } from './Sponsors';
import { SpeakersList } from '../../../data/speakersList';
import { TopicsList } from '../../../data/TopicsList';
import { KeynoteList } from '../../../data/KeynotesList';
import Alberto from '../../../assets/images/speakers/AlbertoGuijosaC.jpg';
import { SpeakerModal } from '../speakers/SpeakersWrapper';
import SpecialIssueBanner from '../specialIssue/HOME_Revista_SNN2024.jpg';
import { Link } from 'react-router-dom';
import { Fees } from '../fees/Fees';

const HomeWrapper = memo(() => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [selectedSpeaker, setSelectedSpeaker] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const specialGuestList = [
    {
      id: 0,
      name: 'Prof. Alberto Güijosa',
      affiliation: 'ICN-UNAM, Mexico',
      img: Alberto,
      topic_id: null,
      resume:
        'Alberto Güijosa is a Professor at the Institute of Nuclear Sciences of the National Autonomous University of Mexico, where he is additionally the Director of the Graduate Program in Physical Sciences. He got his Ph.D. in 1999 at Princeton University, specializing in string theory. For his work in the holographic (or AdS/CFT) correspondence, he was awarded the 2010 Research Prize of the Mexican Academy of Sciences.',
      title: "Some Essentially New Physical Ideas: Feynman's Legacy",
      abstract:
        '<p>Richard P. Feynman is widely recognized as one of the most original and influential theoretical physicists of the twentieth century. This talk will provide a brief overview of some of his contributions, with emphasis on his Nobel-Prize-winning work, at a level accessible to non-specialists.</p>',
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectSpeaker = (speakerId, list) => {
    console.log(speakerId, list);
    const speaker = list.find((speaker) => speaker.id === speakerId);
    setSelectedSpeaker(speaker);
    setIsModalOpen(true);
  };

  const access_token = localStorage.getItem('access_token');

  useEffect(() => {
    if (hash) {
      console.log('Getting token');
      console.log(hash.substring(1));
      const pairs = hash.substring(1).split('&');
      const result = {};

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        result[key] = decodeURIComponent(value);
      });

      localStorage.setItem('id_token', result['id_token']);
      localStorage.setItem('access_token', result['access_token']);
      localStorage.setItem('expires_in', result['expires_in']);
      localStorage.setItem('token_type', result['token_type']);

      navigate('/home');
    }
  }, []);

  useEffect(() => {
    if (access_token) {
      navigate('/home');
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v5.0';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className={isModalOpen ? '' : 'hide'}>
        <SpeakerModal speaker={selectedSpeaker} closeModal={closeModal} />
      </div>
      <div className="content">
        <Banner />
        <div className="special-issue-banner-wrapper snn-page-section">
          <Link to="/special-issue">
            <img src={SpecialIssueBanner} />
          </Link>
        </div>
        <Fees />
        <Topics />
        <div className="snn-page-section snn-user-home-main-content-grid">
          <section className="plenary-grid">
            <p className="snn-user-homa-main-content-text">Speakers</p>
            <div className="snn-user-home-speakers-wrapper">
              {SpeakersList.map((speaker) => (
                <div
                  className="snn-user-home-main-speaker-wrapper"
                  key={speaker.name}
                  onClick={() => selectSpeaker(speaker.id, SpeakersList)}
                >
                  <img
                    className="snn-user-home-main-speaker-wrapper-speaker"
                    src={speaker.img}
                    alt={speaker.name}
                  />
                  <img
                    className="snn-user-home-main-speaker-wrapper-topic"
                    src={TopicsList.filter((t) => t.id === speaker.topic_id)[0].src_shield}
                  />
                </div>
              ))}
            </div>
          </section>
          <section className="plenary-grid">
            <p className="snn-user-homa-main-content-text">Keynotes</p>
            <div className="snn-user-home-speakers-wrapper">
              {KeynoteList.map((speaker) => (
                <div
                  className="snn-user-home-main-speaker-wrapper"
                  key={speaker.name}
                  onClick={() => selectSpeaker(speaker.id, KeynoteList)}
                >
                  <img
                    className="snn-user-home-main-speaker-wrapper-speaker"
                    src={speaker.img}
                    alt={speaker.name}
                  />
                  <img
                    className="snn-user-home-main-speaker-wrapper-topic"
                    src={TopicsList.filter((t) => t.id === speaker.topic_id)[0].src_shield}
                  />
                </div>
              ))}
            </div>
          </section>
          <section className="plenary-grid">
            <p className="snn-user-homa-main-content-text">Special Guest</p>
            <div className="snn-user-home-speakers-wrapper">
              <div
                className="snn-user-home-main-speaker-wrapper"
                onClick={() => selectSpeaker(0, specialGuestList)}
              >
                <img
                  className="snn-user-home-main-speaker-wrapper-speaker"
                  src={Alberto}
                  alt="Alberto"
                />
              </div>
            </div>
          </section>
        </div>
        <Sponsors />
        <About />
        <section className="snn-page-section">
          <h2 className="snn-title">Social media</h2>
          <div className="snn-social-wrapper">
            <div
              className="fb-page"
              data-href="https://www.facebook.com/simposiocnyn"
              data-tabs="timeline"
              data-width="1500"
              data-height="500"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/simposiocnyn"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/simposiocnyn">
                  Simposio de Nanociencias y Nanomateriales
                </a>
              </blockquote>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
});
HomeWrapper.displayName = 'HomeWrapper';
export default HomeWrapper;
