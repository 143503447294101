import TopLogoComponent from './TopLogoComponent';

export const Logo = () => {
  return (
    <div className="snn-top-menu-logo-wrapper">
      <TopLogoComponent
        alt="Symposium of Nanoscience and Nanotechnology Logo"
        className="snn-top-menu-logo"
      />
    </div>
  );
};
