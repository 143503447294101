import { TopBar } from '../../top-bar/TopBar';
import { PublicOptions } from '../../../data/publicOptions';
import { Footer } from '../../footer/Footer';
import './Schedule.css';
import MapImage from '../../../assets/map.jpeg';

export const Schedule = () => {
  return (
    <>
      <TopBar options={PublicOptions} />
      <div className="content">
        <section className="snn-page-section">
          <h2 className="snn-title">Program</h2>
          <div className="schedule-links-wrapper">
            <p>
              <a
                className="email-link"
                href="https://nnsymposium.s3.us-west-1.amazonaws.com/schedule/O4F_SNN-2024-Program.pdf"
              >
                <span className="material-symbols-outlined">download</span> Download program
              </a>
            </p>
            <p>
              <a
                className="email-link"
                href="https://nnsymposium.s3.us-west-1.amazonaws.com/schedule/SNN+2024_POSTER+SESSION_1_EVEN.pdf"
              >
                <span className="material-symbols-outlined">download</span> Download program -
                Session poster 1
              </a>
            </p>
            <p>
              <a
                className="email-link"
                href="https://nnsymposium.s3.us-west-1.amazonaws.com/schedule/SNN2024_POSTER+SESSION_2_ODD.pdf"
              >
                <span className="material-symbols-outlined">download</span> Download program -
                Session poster 2
              </a>
            </p>
          </div>
          <div className="schedule-grid">
            <div className="schedule-grid-day">
              <h3 className="schedule-grid-day-title">Day 1</h3>
              <h2 className="schedule-grid-day-date">Monday, May 13</h2>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:00 AM - 2:00 PM</p>
                <p>
                  Registration/Workshops <br />
                  <small className="schedule-grid-event-location">
                    CNyN Building “C” and CICESE auditorium
                  </small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:00 PM - 4:15 PM</p>
                <p>
                  Opening Ceremony <br />
                  <small className="schedule-grid-event-location">Gymnasium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:15 PM - 5:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">SPECIAL GUEST ✨</small> <br />
                  Some Essentially New Physical Ideas: Feynman&apos;s Legacy <br />
                  Dr. Alberto Güijosa <br />
                  <small className="schedule-grid-event-location">Gymnasium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">5:15 PM - 6:15 PM</p>
                <p>
                  Folkloric ballet
                  <br />
                  <small className="schedule-grid-event-location">Gymnasium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">6:15 PM - 8:00 PM</p>
                <p>
                  Welcome Cocktail
                  <br />
                  <small className="schedule-grid-event-location">Gymnasium CICESE</small>
                </p>
              </div>
            </div>
            <div className="schedule-grid-map">
              <img src={MapImage} alt="Map" className="schedule-map" />
            </div>
            <div className="schedule-grid-day">
              <h3 className="schedule-grid-day-title">Day 2</h3>
              <h2 className="schedule-grid-day-date">Tuesday, May 14</h2>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:00 AM - 9:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-01</small> <br />
                  Infrared emission of sputtering targets <br />
                  Muhl S. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:15 AM - 9:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-01</small> <br />
                  Electrical performance before and after mecanical bending cycles in p-type
                  ZnO:N/n-type ZnO microdiodes fabricated by photolithography on flexible polyimide{' '}
                  <br />
                  Regalado-Contreras A. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:30 AM - 9:45 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-01</small> <br />
                  Study and characterization of quantum dots for the theragnosis of triple-negative
                  breast cancer <br />
                  Velazquez Castillo H. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:45 AM - 10:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">NP-O-01 </small> <br />
                  Engineering metamaterials for passive daytime radiative cooling <br />
                  González-Alcalde A. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:00 AM - 11:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PLENARY 1 ✨ </small> <br />
                  Conducting nanowires assembled by DNA Origami <br />
                  Artur Erbe
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:00 AM - 11:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">TECH-O-01</small> <br />
                  Enhanced AFM Imaging Through Smart Data Acquisition and Multidimensional
                  Characterization <br />
                  Murillo-Bracamontes E. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:15 AM - 11:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-02</small> <br />
                  Laser induced carbonization in lignocellulosic compounds <br />
                  Esqueda-Barrón Y. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:30 AM - 12:00 PM</p>
                <p>
                  Coffee Break ☕️
                  <br />
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:00 PM - 12:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 1 ✨</small> <br />
                  Synthesis, Crystallography and Properties of Materials for Extreme Environments{' '}
                  <br />
                  Olivia Graeve <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:30 PM - 12:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-02</small> <br />
                  Thermodynamics and kinetics of Methylene Blue onto differents carbon materials
                  <br />
                  Cordova Lozano F. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:45 PM - 1:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-02</small> <br />
                  The production and architecture of recombinant protein-based nanoparticles are
                  affected by culture conditions in thermoinduced E. coli
                  <br />
                  Trujillo-Roldán M. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:00 PM - 1:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">NP-O-02</small> <br />
                  Volumetric temperature mapping using light sheet microscopy and upconversion
                  fluorescence from nano-rare earth composites
                  <br />
                  Barron-Ortiz D. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:15 PM - 1:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-03</small> <br />
                  Local effective piezo-electric response for the polycrystalline lead-free
                  perovskite BCZT bulk ceramic versus thin film
                  <br />
                  Herrera-Pérez G. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:30 PM - 1:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-15</small> <br />
                  Propane oxidation over AuCu nanoparticles supported on titania: Effect of the
                  bimetallic composition and activation treatments
                  <br />
                  González-Araiza, D. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:45 PM - 2:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-18</small> <br />
                  NanoYSZ: Solid State Ba0ery and Memristor
                  <br />
                  Tiznado, H. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">2:00 PM - 2:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">NP-O-03</small> <br />
                  Plasmonic studies of metallic nanoparticles using DNA origami as a template
                  <br />
                  Samano E. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">2:15 PM - 4:00 PM</p>
                <p>Lunch 🍔</p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:00 PM - 4:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 2 ✨</small> <br />
                  Impact of dipole moment values of solution additives on air-processed perovskite
                  thin films.
                  <br />
                  Hailin Zhao Hu <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:30 PM - 4:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-04</small> <br />
                  Modulation of optical and electrical properties of NiOx ultrathin films as a
                  function of oxygen pressure via reactive pulsed laser deposition
                  <br />
                  Silva Contreras I. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:45 PM - 5:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-14</small> <br />
                  Ni-Pd catalysts supported on Al-Y applied in CO2 hydrogenation
                  <br />
                  Carrillo Escalante D. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">5:00 PM - 6:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">
                    Even numbers of the key codes
                  </small>{' '}
                  <br />
                  POSTER SESSION I<br />
                  <small className="schedule-grid-event-location">Gymnasium CICESE</small>
                </p>
              </div>
            </div>
            <div className="schedule-grid-day">
              <h3 className="schedule-grid-day-title">Day 3</h3>
              <h2 className="schedule-grid-day-date">Wednesday, May 15</h2>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:00 AM - 9:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 3 ✨</small> <br />
                  Strategies to increase the photoactivity of semiconductors in the hydrogen
                  evolution reaction
                  <br />
                  Rodolfo Zanella Specia <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:30 AM - 9:45 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-05</small> <br />
                  Zn recovery in sphalerite activated with CuS nanoparticles <br />
                  Ávila Márquez D. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:45 AM - 10:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-05</small> <br />
                  Catalytic reduction of Cr(VI) using Pd nanoparticles supported onto TiO
                  <sub>2</sub> <br />
                  Cordova Lozano F. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:00 AM - 10:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-04</small> <br />
                  Increasing the production of external bacterial vesicles through acoustic
                  resonance mixing <br />
                  Valdez-Cruz N. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:15 AM - 10:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">NP-O-04</small> <br />
                  Nonparametric reconstruction of the statistical properties of penetrable,
                  isotropic randomly rough surfaces from in-plane, co-polarized light scattering
                  data: Application to computer-generated and experimental scattering data <br />
                  Simonsen I. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:30 AM - 10:45 AM</p>
                <p>
                  <small className="schedule-grid-event-location">LECTURE</small> <br />
                  ISASA <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:45 AM - 11:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-06</small> <br />
                  Maximum entanglement of symmetric states under unitary gates
                  <br />
                  Serrano-Ensástiga E. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:00 AM - 11:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-06</small> <br />
                  Selective oxidation of 4-aminophenol on gold-based nanoreactors using capsules of
                  different nature
                  <br />
                  Flores H.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:15 AM - 11:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-12</small> <br />
                  Probing the mechanical response and binding interactions of pathogens at the
                  single molecular level
                  <br />
                  Cuéllar Camacho J.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:30 AM - 12:00 PM</p>
                <p>
                  Coffee Break ☕️
                  <br />
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:00 PM - 12:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 4 ✨</small> <br />
                  Improving light absorption in silicon by changing the momentum properties of light
                  <br />
                  Eric Potma <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:30 PM - 12:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">NP-O-05 </small> <br />
                  Second harmonic scattering from metallic nanoparticles with linearly and
                  elliptically polarized fundamental light
                  <br />
                  Slemp M. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:45 PM - 1:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-07 </small> <br />
                  Exploring contaminant agents adsorption on functionalized porous silicon: insights
                  from first principles investigation
                  <br />
                  Diaz T. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:00 PM - 1:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-07</small> <br />
                  On the inhibition efficiency prediction based on global molecular descriptors
                  within conceptual DFT
                  <br />
                  Robles Hernández, J. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour"> 1:15 PM - 2:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PLENARY 2 ✨ </small> <br />
                  In vitro reconstituted virus-like particles for mRNA gene delivery <br />
                  William M. Gelbart
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">2:15 PM - 4:00 PM</p>
                <p>Lunch 🍔</p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:00 PM - 6:00 PM</p>
                <p>
                  SOCIAL ACTIVITY
                  <br />
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">6:00 PM - 7:00 PM</p>
                <p>
                  UABC Guitarritas
                  <br />
                  José Luis Munguía <br />
                  <small className="schedule-grid-event-location">SUM CICESE </small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">7:00 PM - 8:30 PM</p>
                <p>
                  BANQUET
                  <br />
                  <small className="schedule-grid-event-location">SUM CICESE</small>
                </p>
              </div>
            </div>
            <div className="schedule-grid-day">
              <h3 className="schedule-grid-day-title">Day 4</h3>
              <h2 className="schedule-grid-day-date">Thursday, May 16</h2>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:00 AM - 9:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 4 ✨</small> <br />
                  A Look at the Current Status of the Technology and the Global Market
                  <br />
                  Israel Mejia <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:30 AM - 9:45 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-08</small> <br />
                  On the N-doping mechanism of N-doped Graphene by a post-treatment method <br />
                  Amezcua-Navarro M.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:45 AM - 10:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-08</small> <br />
                  Real-time observations of catalytic activity of single suspended ZSM-5 zeolite
                  crystals <br />
                  Garces V. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:00 AM - 10:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-06</small> <br />
                  Virus-based enzymatic nanoreactors for enzyme replacement therapy <br />
                  Vazquez-Duhalt R. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:15 AM - 10:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">NP-O-06</small> <br />
                  Design of squeezed light sources in a silicon nitride platform
                  <br />
                  Casillas Rodríguez N. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:30 AM - 10:45 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-09</small> <br />
                  High-entropy rare earth orthochromites using wet chemistry synthesis: structure
                  and optical characterization from YbCrO3 TO Yb0.2Tm0.2Gd0.2Ce0.2La0.2CrO3 solid
                  solutions <br />
                  García Posada J. <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:45 AM - 11:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-09</small> <br />
                  Reduction of Nitrophenol: effect by the shell of gold inverse catalysts
                  <br />
                  Vargas L.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:00 AM - 11:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-07</small> <br />
                  Analysis of the Influence of Carbon-based nanostructured materials on the
                  performance of specific crops in hydroponics
                  <br />
                  Luna Lopez J.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:15 AM - 11:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-10</small> <br />
                  Antifungal activity and Differential Responses of CaZn2(OH)6×2H2O and ZnO
                  Nanoparticles
                  <br />
                  Lázaro Mass S.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:30 AM - 12:00 PM</p>
                <p>
                  Coffee Break ☕️
                  <br />
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:00 PM - 12:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 6 ✨</small> <br />
                  New Nanostructures for Increased Selectivity and Stability in Catalysis
                  <br />
                  Francisco Zaera <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:30 PM - 12:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-08 </small> <br />
                  Photoactive nanocapsules based on sodium alginate and chitosan as a therapeutic
                  strategy for gynecological cancers
                  <br />
                  Garcia Mar J.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:45 PM - 1:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-11</small> <br />
                  Comparative of Na-δ-MnO2 synthesized by electro-oxidation of manganese compounds
                  obtained by CVD and ALD techniques
                  <br />
                  Pérez-Diaz P.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:00 PM - 1:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-11</small> <br />
                  Models for the prediction of corrosion inhibition efficiency of common drugs on
                  steel surfaces: A rationalized comparison among methodologies
                  <br />
                  Miralrio A.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour"> 1:15 PM - 2:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PLENARY 3 ✨ </small> <br />
                  Second Harmonic Generation of Materials at the Nanoscale: From Incoherent to
                  Coherent Scattering <br />
                  Pierre-François Brevet
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">2:15 PM - 4:00 PM</p>
                <p>Lunch 🍔</p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:00 PM - 4:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 9 </small> <br />
                  Biocide nanomaterials deposited on textiles for personal protection against
                  bacteria and virus <br />
                  Sergio Fuentes Moyado
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:30 PM - 4:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-09</small> <br />
                  Effect of hybrid nanoparticles loaded with fucoxanthin and C-dots on PSEN2 N141l
                  astrocytes in Alzheimer´s Disease
                  <br />
                  Silvestre Martinez J.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:45 PM - 5:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-09</small> <br />
                  Studying surface modification with gold nanoparticles and biopolymers through
                  electrochemical impedance spectroscopy
                  <br />
                  Moreno-Grijalva G.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">5:00 PM - 6:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">
                    Odd numbers of the key codes
                  </small>{' '}
                  <br />
                  POSTER SESSION II
                  <br />
                  <small className="schedule-grid-event-location">Gymnasium CICESE</small>
                </p>
              </div>
            </div>
            <div className="schedule-grid-day">
              <h3 className="schedule-grid-day-title">Day 5</h3>
              <h2 className="schedule-grid-day-date">Friday, May 17</h2>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:00 AM - 9:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 7 ✨</small> <br />
                  Production of novel nanobiomaterials and devices generated from the assembly of
                  viral proteins: From Bioprocess Monitoring to Bioprocess Integration
                  <br />
                  Tonatiuh Ramírez Reivich
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:30 AM - 9:45 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-12</small> <br />
                  Antimicrobial activity of functionalized fabrics modified with ion exchanged
                  zeolite nanomaterials
                  <br />
                  Sánchez-López P.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">9:45 AM - 10:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-10 </small> <br />
                  Internalization and cytotoxicity of polyethylene terephthalate (PET) nanoparticles
                  in the human breast cell line MDA-MB-231 <br />
                  Soria-Angeles P.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">10:00 AM - 11:00 AM</p>
                <p>
                  <small className="schedule-grid-event-location">PLENARY 4 ✨ </small> <br />
                  Shattering Traditional Boundaries: The Integration of AI in Materials Science
                  Discovery
                  <br />
                  Aldo Romero
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:00 AM - 11:15 AM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-13</small> <br />
                  Li-ion storage in amorphous GeSnSe-based anode
                  <br />
                  Rodriguez J.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:15 AM - 11:30 AM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-13</small> <br />
                  Mechanisms of cytotoxicity induced by copper ions and copper oxide nanoparticles
                  in excretory system cells
                  <br />
                  Juarez-Moreno K.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">11:30 AM - 12:00 PM</p>
                <p>
                  Coffee Break ☕️
                  <br />
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:00 PM - 12:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">KEYNOTE 8✨</small> <br />
                  The dynamics of molecules and nanostructures in liquids
                  <br />
                  Minerva González Melchor <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:30 PM - 12:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">PCNC-O-13 </small> <br />
                  The magnetic states in Co/MoS2 catalytic microparticles
                  <br />
                  Ramos M.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">12:45 PM - 1:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-14</small> <br />
                  A DFT study of the wannier exciton binding energies in lead-free halide double
                  perovskites
                  <br />
                  Castillo-Sánchez J.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:00 PM - 1:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">TECH-O-02</small> <br />
                  Mask based Lithography for pattern generation with matter waves
                  <br />
                  Simonsen V.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:15 PM - 1:30 PM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-11</small> <br />
                  Drug bio-nanocarrier design: enhancing antineoplastic effects
                  <br />
                  Carrasco-Torres G.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:30 PM - 1:45 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-15</small> <br />
                  Effect of annealing temperature to obtain NiCo2O4 thin films chemically deposited
                  <br />
                  Martinez-Gil M.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">1:45 PM - 2:00 PM</p>
                <p>
                  <small className="schedule-grid-event-location">BIO-O-05</small> <br />
                  Nanoparticles@polymer hybrid films: a promising strategy in the fight against
                  bacterial spread
                  <br />
                  Rincon K.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">2:00 PM - 2:15 PM</p>
                <p>
                  <small className="schedule-grid-event-location">AMPN-O-16</small> <br />
                  Secondary greenhouse gases adsorbed on molybdenum disulfide monolayers modified
                  with group 11 trimers embedded on sulfur vacancies
                  <br />
                  Rangel-Cortes E.
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">2:15 PM - 4:00 PM</p>
                <p>
                  CLOSING CEREMONY
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
              <div className="schedule-grid-event">
                <hr />
                <p className="schedule-grid-event-hour">4:00 PM - 8:00 PM</p>
                <p>
                  DEPARTURE
                  <br />
                  <small className="schedule-grid-event-location">Auditorium CICESE</small>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
