import Logos from '../../../assets/acceptance-letter/proceeding logos.svg';
import Signature from '../../../assets/acceptance-letter/signature.png';
import UNAM from '../../../assets/acceptance-letter/unam.png';
import PropTypes from 'prop-types';
import Background from '../../../assets/acceptance-letter/Proceeding sheet.png';

export const AcceptanceLetterComponent = ({ abstract }) => {
  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <>
      <button className="print-button" onClick={Print}>
        <span className="material-symbols-outlined">print</span> Print
      </button>
      <div id="printablediv" className="acceptance-letter-wrapper">
        <div className="acceptance-letter-logos-wrapper">
          <img src={Logos} alt="Proceeding logos" />
        </div>
        <p className="acceptance-letter-title">ACCEPTANCE LETTER</p>
        <div className="acceptance-letter-text-wrapper">
          <p>
            Dear{' '}
            <strong>
              {abstract.authors[0].lastName} {abstract.authors[0].name[0]}.,
            </strong>
          </p>
          <p>
            On behalf of the Organizing Committee, it is my pleasure to inform you that the abstract
            entitled:
          </p>
          <p>
            <strong>
              {abstract.code ? abstract.code : ''}: “{abstract.title}.”
            </strong>
          </p>
          <p>
            Has been accepted as an {abstract.accepted} presentation in the Symposium of Nanoscience
            and Nanomaterials 2024 to be held in Ensenada, Baja California from May 13 to 17, 2024.
          </p>
          {abstract.presentation_type === 'oral' ? (
            <p>
              We invite you to register on the website before April 15, 2024. In the case of oral
              presentation, if you are not registered by May 1st your work might be changed to
              poster. Please send an email with confirmation of your presence since this is an
              in-person presentation.
            </p>
          ) : null}

          <p>Sincerely,</p>
          <div className="acceptance-letter-signature-wrapper">
            <img className="signature" src={Signature} alt="Signature" />
          </div>
          <img className="unam" src={UNAM} alt="UNAM" />
          <img src={Background} className="background-img" />
        </div>
      </div>
    </>
  );
};

AcceptanceLetterComponent.propTypes = {
  abstract: PropTypes.any.isRequired,
};
