import { SideMenu } from '../user-home/SideMenu';
import { AbstractEdit } from './AbstractEdit';

export const AbstractEditWrapper = () => {
  return (
    <div className="snn-user-home-main-wrapper">
      <SideMenu />
      <AbstractEdit />
    </div>
  );
};
