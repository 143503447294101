// import { ReactComponent as BannerIMG } from '../../../assets/2024/BANNER.png';
import BannerIMG from '../../../assets/2024/Banner-early_bird.svg';

export const Banner = () => {
  return (
    <>
      <section className="page-section snn-banner">
        <div>
          <img src={BannerIMG} alt="Banner" />
        </div>
      </section>
    </>
  );
};
