import { FormGroup } from './FormGroup';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const AuthorForm = ({ index, setAuthors, authors, moveUp, moveDown, removeAuthor }) => {
  const [affiliations, setAffiliations] = useState(
    authors[index].affiliations ? authors[index].affiliations : [''],
  );
  const [author, setAuthor] = useState(authors[index]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthor((prevAuthor) => ({ ...prevAuthor, [name]: value }));
  };

  const handleChangeCorrespondingAuthor = (e) => {
    const { name, checked } = e.target;
    setAuthor((prevAuthor) => ({ ...prevAuthor, [name]: checked }));
  };

  const handleChangeAffiliation = (index, e) => {
    const { value } = e.target;
    setAffiliations((prevAffiliations) => {
      const newAffiliations = [...prevAffiliations];
      newAffiliations[index] = value;
      return newAffiliations;
    });
  };

  useEffect(() => {
    let newAuthors = [...authors];
    newAuthors[index] = author;
    setAuthors(newAuthors);
  }, [author]);

  useEffect(() => {
    setAuthor(authors[index]);
  }, [authors]);

  useEffect(() => {
    let newAuthors = [...authors];
    newAuthors[index].affiliations = affiliations;
    setAuthors(newAuthors);
  }, [affiliations]);

  const addAffiliation = () => {
    let newField = '';
    setAffiliations([...affiliations, newField]);
  };
  const removeAffiliation = (index) => {
    let newFields = [...affiliations];
    newFields.splice(index, 1);
    setAffiliations(newFields);
  };

  return (
    <div>
      <div className="snn-abstract-submission-two-columns-form">
        <div className="snn-expand-grid-2 snn-abstract-submission-author-actions-wrapper">
          <button
            className="snn-abstract-submission-move-up snn-abstract-submission-author-action-button"
            onClick={() => moveUp(index)}
          >
            <span className="material-symbols-outlined">keyboard_arrow_up</span>
          </button>
          <button
            className="snn-abstract-submission-move-down snn-abstract-submission-author-action-button"
            onClick={() => moveDown(index)}
          >
            <span className="material-symbols-outlined">keyboard_arrow_down</span>
          </button>
          <button
            className="snn-abstract-submission-remove snn-abstract-submission-author-action-button"
            onClick={() => removeAuthor(index)}
          >
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        <h2 className="snn-user-home-main-content-large-semi snn-expand-grid-2">Author</h2>
        <FormGroup label="Name" value={author.name} setValue={handleChange} name="name" />
        <FormGroup
          label="Last name"
          value={author.lastName}
          setValue={handleChange}
          name="lastName"
        />
        <div className="snn-form-group">
          <label>Degree</label>

          <select id="degree" name="degree" value={author.degree} onChange={(e) => handleChange(e)}>
            <option selected value="Bachelor student">
              Bachelor student
            </option>
            <option name="degree" value="Master student">
              Master student
            </option>
            <option name="degree" value="Phd student">
              Phd student
            </option>
            <option name="degree" value="Postdoc Researcher">
              Postdoc Researcher
            </option>
            <option name="degree" value="Researcher">
              Researcher
            </option>
            <option name="degree" value="Professor">
              Professor
            </option>
          </select>
        </div>

        <FormGroup label="Email" value={author.email} setValue={handleChange} name="email" />
        <div className="snn-form-group snn-expand-grid-2">
          <label>
            <input
              type="checkbox"
              name="correspondingAuthor"
              checked={author.correspondingAuthor}
              onChange={handleChangeCorrespondingAuthor}
            />
            Corresponding author
          </label>
        </div>
        <h2 className="snn-user-home-main-content-large-semi">Affiliations</h2>
        {affiliations.map((affiliation, index) => {
          return (
            <div
              key={index}
              className="snn-abstract-submission-affiliation-wrapper snn-expand-grid-2"
            >
              <div className="snn-abstract-submission-affiliation-form-wrapper">
                <FormGroup
                  label=""
                  value={affiliation}
                  setValue={(e) => handleChangeAffiliation(index, e)}
                />
              </div>
              <button
                className="snn-abstract-submission-remove-primary"
                onClick={() => removeAffiliation(index)}
              >
                <span className="material-symbols-outlined">close</span>
              </button>
            </div>
          );
        })}
        <div className="snn-expand-grid-2 snn-abstract-submission-add-affiliation-button-wrapper">
          <button
            className="snn-abstract-submission-add-affiliation-button"
            onClick={addAffiliation}
          >
            Add affiliation<span className="material-symbols-outlined">add</span>
          </button>
        </div>
      </div>
    </div>
  );
};

AuthorForm.propTypes = {
  index: PropTypes.number.isRequired,
  setAuthors: PropTypes.func.isRequired,
  authors: PropTypes.array.isRequired,
  moveUp: PropTypes.func.isRequired,
  moveDown: PropTypes.func.isRequired,
  removeAuthor: PropTypes.func.isRequired,
};
